
.ant-select-selection-placeholder {
    font-size: inherit !important;
}

/********* ant-upload-select *********/
.ant-upload-list-item {
    background-color: #fafafa !important;
    padding: 20px 10px !important
}

.ant-upload-list-item * {
    opacity: 1 !important
}

.ant-upload-list-item .ant-btn-icon-only * {
    opacity: 1 !important;
    color: tomato
}

.ant-upload.ant-upload-select {
    display: block !important;
}

/********* ant-table-pagination *********/
.ant-table-pagination.ant-pagination {

    margin: 16px !important;
}

/*
.ant-pagination-item {

    border-radius: 50% !important;
}
*/
/********* ant-page-header *********/
.ant-page-header-compact .ant-page-header-heading {
    flex-wrap: unset !important;
}

.ant-page-header-heading-title {
    font-size: inherit !important;
}

/********* ant-tag *********/
.ant-select-multiple .ant-select-selection-item-content,
.ant-select-multiple .ant-select-selection-item-remove *,
.ant-tag,
.ant-tag * {
    font-size: 12px !important;

}

.ant-tag {

}

.ant-tag > .anticon + span, .ant-tag > span + .anticon {
    margin-left: 7px;
    margin-right: 5px;

}

.ant-tag:not(.ant-select .ant-tag) {
    font-weight: normal;
    margin: 0;
    text-align: center !important;
}

.ant-select-multiple .ant-select-selection-item-remove * {
    font-size: 10px !important;
    margin-left: 2px;
}

/********* ant-layout *********/
.ant-layout {
    background-color: #fff !important;
}

.ant-tabs-tab,
.ant-table-tbody > tr > td,
.ant-table-thead > tr > th,
.ant-badge-default .ant-badge-count {
    color: inherit !important;
}

.ant-tabs.ant-tabs-left .ant-tabs-tab {
    border-radius: 4px 4px 0px 0px !important;
}


/********* ant-select *********/

.ant-select-item-option-content {

    white-space: normal !important;
}

/********* ant-space *********/
/********* table *********/
.ant-table {
    padding: 10px !important;
    /*border:1px solid #f0f0f0;*/
}

.ant-table tr > th {
    user-select: none !important;
}

table th, table th > span {
    font-size: 95% !important;
}

.ant-table .ant-table-column-sorters .ant-table-column-sorter-inner {
    margin-left: 10px !important;
}

.ant-table-wrapper .ant-table-column-sorters {
    display: unset
}

.ant-table.ant-table-small tr:not(.ant-table-measure-row) td {
    padding: 4px 8px !important
}


.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    box-shadow: none !important;
}

.ant-table-cell.ant-table-selection-column {
    padding: 0 !important;
}


.ant-table-thead > tr > th {
    white-space: nowrap !important;
    font-weight: bold !important;
}

th.ant-table-cell-fix-left *,
th.ant-table-cell-fix-right * {
    font-size: inherit !important;
}

.ant-table td.ant-table-cell.nowrap {
    white-space: nowrap !important;
}


/*********  rc-virtual-list-scrollbar-thumb *********/
.rc-virtual-list-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.08) !important;
}

.rc-virtual-list-scrollbar {
    display: block !important;
}

/********* ant-checkbox *********/


.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #fff !important;
}


.ant-input-affix-wrapper {
    padding: 2.5px 11px !important;
}


/********* ant-segmented *********/


.ant-segmented-item-selected {
    font-weight: bold;
}

/********* tooltip *********/


.ant-popover {
    z-index: 10000 !important;
}

.ant-popover-inner {
}

.ant-popover-inner,
.ant-tooltip-inner,
.ant-tooltip-arrow-content {
    color: #000 !important;
    font-size: 0.9em !important;
    min-height: unset !important;
}

.ant-tooltip {
    max-width: 500px !important;;;
}

/********* badge *********/

.ant-badge-dot {
    background-color: tomato !important;
    right: -4px;
}

.ant-badge {
    z-index: 0 !important;
}

.ant-badge-default .ant-badge-count {
    background-color: #fff;
    box-shadow: 0 0 0 1px #d9d9d9 inset;
}

.ant-scroll-number {
    overflow: hidden;
}

.ant-badge-default .ant-badge-count {
    /* background-color: #00b5ad;*/
    background-color: #e6f0fc;
    box-shadow: none;
}

.ant-badge-default.red .ant-badge-count {
    /* background-color: #00b5ad;*/
    background-color: tomato;
    box-shadow: none;
    color: #fff !important;
}

.ant-badge-count * {
    font-size: 12px
}

/********* ant-drawer *********/
.ant-drawer {
    transform: translateX(0px) !important;
}

.ant-drawer .ant-drawer-header .--title {
    font-size: inherit;
}

title
.ant-drawer > * {
    font-size: inherit !important;;
}

.ant-drawer-header-title {
    padding: 16px 24px 16px 16px !important;

}

.ant-drawer-close {
    color: rgba(0, 0, 0, .77) !important;
}

.ant-drawer .ant-drawer-header {
    line-height: 1.5;
}

.ant-drawer .ant-drawer-header {
    padding: 0 !important;
    border: 0 !important;
}

.ant-drawer-footer {
    border: none !important;
}

.ant-drawer-body {
    padding-top: 0 !important;
}

.ant-drawer-right > .ant-drawer-content-wrapper {
    transform: translateX(0px) !important;
}


.ant-drawer .details > .ant-row > .ant-col:first-child {
    text-align: right;
    font-size: inherit !important;
    white-space: normal;
}

/********* ant-select *********/
.ant-select-item-group {
    font-size: inherit !important;
}

/* si badge dans les options du select, masque le badge de l'item une fois  selectionné*/
.ant-select-selector .ant-select-selection-item .ant-badge {
    display: none !important;
}

.ant-badge-multiple-words {
    padding: 0 6px !important;
}

.ant-badge.ant-badge-status .ant-badge-status-processing {
    color: tomato !important;
    background-color: tomato !important;
}

/********* ant-form *********/
.ant-form-vertical .ant-form-item-label > label {

    white-space: normal !important;
    margin-right: 10px !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    font-weight: normal !important;;
}

.ant-form.ant-form-horizontal .ant-form-item {
    margin-bottom: 10px !important;
}

.ant-form.ant-form-vertical .ant-form-item {
    margin-bottom: 12px !important;
}

.ant-form-vertical .ant-form-item-label > label {
    height: 0px !important;
}


.ant-col.ant-form-item-label {
    padding: 2px !important;
}

.ant-form .ant-form-item-label > label {
    font-size: 0.9em !important;;
    color: var(--label-color) !important;
    white-space: normal;
}

.ant-form-item.--ant-form-item-wrap > div {
    width: 100% !important;
    flex: none !important;
    text-align: left;
}

.ant-form-item-explain > div[role="alert"] {
    font-size: 0.9em !important;
    margin-top: 4px !important;

}

.ant-form-item .ant-form-item-explain-error {
    margin: 6px !important;
}

.ant-form-item-control {

    padding-left: 2px !important;
    padding-right: 2px !important;
}

/********* *********/
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
    bottom: -1px !important;
    /* border: 0px solid; */
}

.ant-tabs.ant-tabs-left .ant-tabs-extra-content {
    margin-bottom: 10px !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: none !important;
    font-weight: 600 !important;
}

.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
    border-left: 1px solid #f0f0f0 !important;
}

.ant-tabs-tab:not(.ant-tabs-tab-active) {
    border-color: transparent !important;
}


.ant-tabs-tab {
    background: transparent !important;
}


.ant-tabs-tab.ant-tabs-tab-disabled {
    color: #00000040 !important;
}

.ant-tabs-tab.ant-tabs-tab-active * {
    color: inherit !important;
}

/********* ant-spin-nested-loading *********/
.ant-spin-nested-loading > div > .ant-spin {
    height: 100vh !important;
    max-height: 100vh !important;
}


/********* ant-message *********/

/********* ant-btn-primary *********/
button.ant-btn {
    box-shadow: none;

}

.ant-btn {
    opacity: 1;
}

.ant-btn:hover,
.ant-btn:hover > * {
    opacity: 0.8;
}

.ant-btn-icon {
    line-height: unset !important;
}

button.ant-btn.ant-btn-primary.ant-btn-circle.ant-btn-sm.ant-btn-icon-only.ant-btn-background-ghost {
    border: none;
}

.ant-btn.ant-btn-sm * {
    font-size: 0.87em !important;
}

.ant-btn.ant-btn-sm:not(.ant-btn-circle) {
    height: 26px !important;
    line-height: 24px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.ant-btn:not(.ant-btn-circle) {

}

.ant-btn-icon-only {

}

/********* ant-drawer *********/
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 0 0.25rem 1rem rgba(48, 55, 66, .15) !important;
}

.ant-picker .ant-picker-input {
}


/*
.ant-input:not(.ant-select input):not(.ant-input-affix-wrapper input) {
    background-color: #9e9e9e08!important;;
    border: 1px solid #d9d9d940!important;;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #9e9e9e08!important;;
    border: 1px solid #d9d9d940!important;;
}
*/
/********* ant-collapse *********/


.ant-collapse > .ant-collapse-item {

    margin: 0 0 8px 0 !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {

    background-color: #faf9f8 !important; /* #e3f2f7 !important;*/
    font-weight: bold;
}

.ant-collapse {
    background-color: transparent !important;
    border: 0 !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    right: 10px !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 8px !important;
}


/*******************/
.ant-notification-notice-message {
    display: table;
}

.ant-notification-notice-message * {
    display: table-cell;
    vertical-align: middle;
}

.ant-notification-notice-message > i {
    padding-right: 10px;
}

/******** juste le border bottom pourtous les input ***/
.ant-input-sm[type="number"] {
    padding: 0 0 0 7px !important;
}

.ant-space.ant-space-vertical {
    width: 100% !important;
}

/**** tree ****/
.ant-tree .ant-tree-treenode .ant-tree-node-content-wrapper {
    cursor: default;
}


/*
.ant-tree .ant-tree-node-content-wrapper:hover,
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: #bae7ffa6 !important;;
}
.ant-tree-treenode:nth-child(2n) > .ant-tree-node-content-wrapper {
    background-color: #f5f5f5c2;
}

 */

/**** divider ****/
.ant-divider-horizontal.ant-divider-with-text {
    margin: 10px 0 !important;
}

.ant-divider-horizontal.bold * {

    font-weight: bold;
}


.ant-upload-list-item {
    width: 500px;
}

/**** hack perso ant-picker-time ****/
li.ant-picker-time-panel-cell.ant-picker-time-panel-cell-disabled,
.ant-picker-time-panel-column::after {
    display: none !important;
    height: 0 !important;
}

.ant-input:not(.ant-input-status-error),
.ant-input-affix-wrapper,
.ant-select > * {
    background-color: rgb(250, 249, 248) !important;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-left-color: transparent !important;
    border-bottom-color: #d9d9d969 !important;
}

.ant-select .ant-select-selection-item > .ant-tag {
    line-height: 20px !important;
}

.ant-input-affix-wrapper {
    font-size: inherit !important;
}

.ant-input-affix-wrapper,
.ant-input-affix-wrapper .ant-input {
    background-color: transparent !important;
}

.ant-input-affix-wrapper-focused,
.ant-input-focused,
.ant-input:focus,
.ant-select-focused * {
    border-color: transparent !important;
}


.ant-layout-sider {
    background-color: transparent !important;
}

.ant-layout-sider * {
    border-width: 0 !important;
}


/*
.ant-tag-green {
    color: #016936 !important;
    border-color: #016936 !important;
    background: #fff !important;

}

.ant-tag-red {
    background: #fff !important;
}

 */
.ant-descriptions-item-label {
    color: rgba(128, 128, 128, 0.6) !important;;
}

.ant-descriptions-item-label:after {
    content: "" !important;
}

.ant-descriptions-title {
    font-size: inherit !important;
}

.ant-steps .ant-steps-item-content *:not(.ant-tag) {
    font-size: inherit !important;
}

.ant-steps-item-description {
    max-width: unset !important;

}

.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-item, .ant-menu-vertical-left .ant-menu-submenu-title, .ant-menu-vertical-right .ant-menu-item, .ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-vertical .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title {

    margin-top: 0px !important;;
}

/********************/


.ant-notification-notice-with-icon .ant-notification-notice-message {
    font-size: inherit !important;
}

/********************/
/**** ant-picker-range ****/
.ant-picker:not(.year):not(.dateTime) {

}

.ant-picker.dateTime .ant-picker-input {
    width: 130px !important;;
}

.ant-picker.year .ant-picker-input {
    width: 56px !important;;
}

.ant-picker.year .ant-picker-input * {
    text-align: center !important;
}

/********************/
.ant-modal-title {
    font-size: inherit !important;
}


.ant-modal-confirm {

    width: 50% !important;
    max-width: 700px !important;
}

.ant-modal-confirm .ant-modal-confirm-title {

    font-size: inherit !important;
    font-weight: bold !important;
}

.ant-modal-confirm .ant-modal-body {
    padding: 20px !important;
}

/********************/
.ant-alert-icon {
    margin-right: 15px !important;;
}

.ant-alert-with-description .ant-alert-message {
    font-size: inherit;
    font-weight: bold;
}

/********************/
.ant-divider-horizontal,
.ant-typography {
    font-size: 14.5px !important;;
}

.ant-switch .ant-switch-inner * {
    font-size: 0.8em !important;;
    font-family: var(--small-font-family) !important;

}

.ant-switch .ant-switch-inner * {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

/********************/
.ant-list-item-meta-title {
    font-size: inherit !important;
}

.ant-list .ant-list-header,
.ant-list .ant-list-footer {
    padding-top: 0px !important;
}


.ant-picker-calendar-full .ant-picker-panel .ant-picker-content th {
    text-transform: capitalize !important;
}

.ant-picker-calendar .ant-picker-cell-disabled {
    opacity: 0.5 !important;
}
