@import url("https://fonts.googleapis.com/css2?family=Michroma&family=Google+Sans+Text&display=swap");


:root {
    --default-font-family: "Google Sans Text";
    --small-font-family: "Google Sans Text";
    --app-title-font-family: "Michroma";
    --label-color: #607D8B;
    --default-font-size: 13.5px;
}

@import url("./../css/common.css");
@import url("./../css/overrides.css");
@import url("./../fabric-icons/css/fabric-icons.css");
@import url("./../css/page.css");

/**************/


.ant-drawer .ant-tabs {
    margin: 0px auto;
    /*  max-width: 1200px;*/
}

ul#navigation li:first-child {
}

ul#navigation li:first-child * {
    text-decoration: none;
}

/********/


/********/


.ant-table th.editable {
    padding-top: 4px !important;
    padding-bottom: 2px !important;
}


.ant-select.ant-select-multiple.--multi-select-full .ant-select-selection-overflow-item {
    width: 100% !important;
}

.ant-select.ant-select-multiple.--multi-select-full .ant-select-selection-item {
    justify-content: space-between !important;
    padding-inline-start: 8px;
    padding-inline-end: 8px;


}

/********************* planning **********************/
.planning .ant-picker-calendar-header {
    display: none !important;
}

.planning.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    height: 26px !important;
    overflow: hidden !important;;
    margin-top: -23.5px !important;
    font-weight: bold !important;;
    text-align: right !important;;
}

.planning.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
    text-align: left !important

}

.planning.ant-picker-calendar-full .ant-picker-panel .ant-picker-content th {
    text-align: left !important;
    padding: 0 0 5px 12px;
    font-weight: bold;
}

.planning .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    display: none;
}

.ant-picker-calendar-header {
    display: none
}

/** menu de l'appli **/
.ant-layout-sider li.anneeCreationSelect {
    cursor: default;
    background-color: #fff !important;;
    padding: 0 !important;
    margin: 10px 0 8px 0 !important;
    width: 100% !important;
    text-align: center !important;
}

.ant-layout-sider li.anneeCreationSelect .ant-picker {
    border: 1px solid #b2b2b252 !important;
    padding: 4px 6px
}

.ant-layout-sider li.anneeCreationSelect button span {
    font-size: 1.2em !important;
    font-weight: bold;

}

.ant-select .ant-tag {
    border-style: dashed;
    background-color: #fff;
}

/** pre-prospect-view **/
#pre-prospect-table tbody tr:not(.ant-table-measure-row) td {
    padding: 8px 6px !important
}

#pre-prospect-view * {
    font-size: 15px
}

#pre-prospect-view #pre-prospect-view-title > :first-child {
    display: table;
}

#pre-prospect-view #pre-prospect-view-title > :first-child > * {
    display: table-cell;
    vertical-align: middle;
    padding-right: 10px;
}

@media screen and (max-width: 600px) {
    .ant-picker:not(.year):not(.dateTime) {
        width: 130px !important;
    }

    #pre-prospect-view * {
    }

    #pre-prospect-view #pre-prospect-view-title {
        width: 100%;
        margin: auto
    }

    #pre-prospect-view #pre-prospect-view-title:first-child {

    }

    #pre-prospect-view form {
        padding: 0 !important;
        box-shadow: none !important;
    }

    #pre-prospect-view form * {
        row-gap: 0 !important;
    }
}


