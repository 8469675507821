/*
  App version 2.109.0 ・ FabMDL2 v4.27 Your use of the content in the files referenced here is subject to the terms of the license at https://aka.ms/fabric-assets-license
*/
@font-face {
    font-family: 'FabricMDL2Icons';
    src: url('../fonts/fabric-icons.woff') format('woff');
}

[class^="ms-Icon--"],
.ms-Icon {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: 'FabricMDL2Icons';
    font-style: normal;
    font-weight: normal !important;
    speak: none;
    font-size: 16px;
    /* ajout pour performance préconisé par lighhouse*/
    font-display: swap
}


.ms-Icon--12PointStar:before {
    content: "\F505";
}

.ms-Icon--6PointStar:before {
    content: "\F504";
}

.ms-Icon--AADLogo:before {
    content: "\ED68";
}

.ms-Icon--Accept:before {
    content: "\E8FB";
}

.ms-Icon--AcceptMedium:before {
    content: "\F78C";
}

.ms-Icon--AccessibiltyChecker:before {
    content: "\F835";
}

.ms-Icon--AccessLogo:before {
    content: "\ED69";
}

.ms-Icon--AccountActivity:before {
    content: "\EFF4";
}

.ms-Icon--AccountBrowser:before {
    content: "\F652";
}

.ms-Icon--AccountManagement:before {
    content: "\F55C";
}

.ms-Icon--Accounts:before {
    content: "\E910";
}

.ms-Icon--ActionCenter:before {
    content: "\E91C";
}

.ms-Icon--ActivateOrders:before {
    content: "\EFE0";
}

.ms-Icon--ActivityFeed:before {
    content: "\F056";
}

.ms-Icon--Add:before {
    content: "\E710";
}

.ms-Icon--AddBookmark:before {
    content: "\F5B7";
}

.ms-Icon--AddConnection:before {
    content: "\F4E1";
}

.ms-Icon--AddEvent:before {
    content: "\EEB5";
}

.ms-Icon--AddFavorite:before {
    content: "\F0C8";
}

.ms-Icon--AddFavoriteFill:before {
    content: "\F0C9";
}

.ms-Icon--AddField:before {
    content: "\E4C7";
}

.ms-Icon--AddFriend:before {
    content: "\E8FA";
}

.ms-Icon--AddGroup:before {
    content: "\EE3D";
}

.ms-Icon--AddHome:before {
    content: "\F17B";
}

.ms-Icon--AddIn:before {
    content: "\F775";
}

.ms-Icon--AddLink:before {
    content: "\E35E";
}

.ms-Icon--AddMedium:before {
    content: "\ECA1";
}

.ms-Icon--AddNotes:before {
    content: "\EAE3";
}

.ms-Icon--AddOnlineMeeting:before {
    content: "\ED8E";
}

.ms-Icon--AddPhone:before {
    content: "\ED96";
}

.ms-Icon--AddReaction:before {
    content: "\F85D";
}

.ms-Icon--AddSpaceAfter:before {
    content: "\E3DF";
}

.ms-Icon--AddSpaceBefore:before {
    content: "\E3DE";
}

.ms-Icon--AddTable:before {
    content: "\E4C6";
}

.ms-Icon--AddTo:before {
    content: "\ECC8";
}

.ms-Icon--AddToShoppingList:before {
    content: "\EA9A";
}

.ms-Icon--AddWork:before {
    content: "\F17C";
}

.ms-Icon--Admin:before {
    content: "\E7EF";
}

.ms-Icon--AdminALogo32:before {
    content: "\F4BA";
}

.ms-Icon--AdminALogoFill32:before {
    content: "\F4BB";
}

.ms-Icon--AdminALogoInverse32:before {
    content: "\ED6A";
}

.ms-Icon--AdminCLogoInverse32:before {
    content: "\ED6B";
}

.ms-Icon--AdminDLogoInverse32:before {
    content: "\ED6C";
}

.ms-Icon--AdminELogoInverse32:before {
    content: "\ED6D";
}

.ms-Icon--AdminLLogoInverse32:before {
    content: "\ED6E";
}

.ms-Icon--AdminMLogoInverse32:before {
    content: "\ED6F";
}

.ms-Icon--AdminOLogoInverse32:before {
    content: "\ED70";
}

.ms-Icon--AdminPLogoInverse32:before {
    content: "\ED71";
}

.ms-Icon--AdminSLogoInverse32:before {
    content: "\ED72";
}

.ms-Icon--AdminYLogoInverse32:before {
    content: "\ED73";
}

.ms-Icon--Airplane:before {
    content: "\E709";
}

.ms-Icon--AirplaneSolid:before {
    content: "\EB4C";
}

.ms-Icon--AirTickets:before {
    content: "\EF7A";
}

.ms-Icon--AlarmClock:before {
    content: "\E919";
}

.ms-Icon--Album:before {
    content: "\E7AB";
}

.ms-Icon--AlbumRemove:before {
    content: "\EC62";
}

.ms-Icon--AlertSettings:before {
    content: "\F8B6";
}

.ms-Icon--AlertSolid:before {
    content: "\F331";
}

.ms-Icon--AlignCenter:before {
    content: "\E8E3";
}

.ms-Icon--AlignHorizontalCenter:before {
    content: "\F4F4";
}

.ms-Icon--AlignHorizontalLeft:before {
    content: "\F4F3";
}

.ms-Icon--AlignHorizontalRight:before {
    content: "\F4F5";
}

.ms-Icon--AlignJustify:before {
    content: "\F51E";
}

.ms-Icon--AlignLeft:before {
    content: "\E8E4";
}

.ms-Icon--AlignRight:before {
    content: "\E8E2";
}

.ms-Icon--AlignVerticalBottom:before {
    content: "\F4F8";
}

.ms-Icon--AlignVerticalCenter:before {
    content: "\F4F7";
}

.ms-Icon--AlignVerticalTop:before {
    content: "\F4F6";
}

.ms-Icon--AllApps:before {
    content: "\E71D";
}

.ms-Icon--AllAppsMirrored:before {
    content: "\EA40";
}

.ms-Icon--AllCurrency:before {
    content: "\EAE4";
}

.ms-Icon--AltText:before {
    content: "\E397";
}

.ms-Icon--AnalyticsLogo:before {
    content: "\F1DE";
}

.ms-Icon--AnalyticsQuery:before {
    content: "\F1DF";
}

.ms-Icon--AnalyticsReport:before {
    content: "\F1E1";
}

.ms-Icon--AnalyticsView:before {
    content: "\F5F1";
}

.ms-Icon--AnchorLock:before {
    content: "\F511";
}

.ms-Icon--Annotation:before {
    content: "\E924";
}

.ms-Icon--AppIconDefault:before {
    content: "\ECAA";
}

.ms-Icon--AppIconDefaultAdd:before {
    content: "\EFDA";
}

.ms-Icon--AppIconDefaultEdit:before {
    content: "\EFDC";
}

.ms-Icon--AppIconDefaultList:before {
    content: "\EFDE";
}

.ms-Icon--Archive:before {
    content: "\F03F";
}

.ms-Icon--ArchiveUndo:before {
    content: "\E3A1";
}

.ms-Icon--AreaChart:before {
    content: "\E9D2";
}

.ms-Icon--ArrangeBringForward:before {
    content: "\F509";
}

.ms-Icon--ArrangeBringToFront:before {
    content: "\F506";
}

.ms-Icon--ArrangeByFrom:before {
    content: "\F678";
}

.ms-Icon--ArrangeSendBackward:before {
    content: "\F508";
}

.ms-Icon--ArrangeSendToBack:before {
    content: "\F507";
}

.ms-Icon--Arrivals:before {
    content: "\EB34";
}

.ms-Icon--ArrowDownRight8:before {
    content: "\EED5";
}

.ms-Icon--ArrowDownRightMirrored8:before {
    content: "\EEF0";
}

.ms-Icon--ArrowTallDownLeft:before {
    content: "\F2BF";
}

.ms-Icon--ArrowTallDownRight:before {
    content: "\F2C0";
}

.ms-Icon--ArrowTallUpLeft:before {
    content: "\F2BD";
}

.ms-Icon--ArrowTallUpRight:before {
    content: "\F2BE";
}

.ms-Icon--ArrowUpRight:before {
    content: "\F069";
}

.ms-Icon--ArrowUpRight8:before {
    content: "\EED4";
}

.ms-Icon--ArrowUpRightMirrored8:before {
    content: "\EEEF";
}

.ms-Icon--Articles:before {
    content: "\EAC1";
}

.ms-Icon--Ascending:before {
    content: "\EDC0";
}

.ms-Icon--AspectRatio:before {
    content: "\E799";
}

.ms-Icon--AssessmentGroup:before {
    content: "\F31A";
}

.ms-Icon--AssessmentGroupTemplate:before {
    content: "\F2B1";
}

.ms-Icon--AssetLibrary:before {
    content: "\EEB6";
}

.ms-Icon--Assign:before {
    content: "\E9D3";
}

.ms-Icon--AssignPolicy:before {
    content: "\E461";
}

.ms-Icon--Asterisk:before {
    content: "\EA38";
}

.ms-Icon--AsteriskSolid:before {
    content: "\F34D";
}

.ms-Icon--ATPLogo:before {
    content: "\EF85";
}

.ms-Icon--Attach:before {
    content: "\E723";
}

.ms-Icon--AustralianRules:before {
    content: "\EE70";
}

.ms-Icon--AuthenticatorApp:before {
    content: "\F6B1";
}

.ms-Icon--AutoDeploySettings:before {
    content: "\F3FA";
}

.ms-Icon--AutoEnhanceOff:before {
    content: "\E78E";
}

.ms-Icon--AutoEnhanceOn:before {
    content: "\E78D";
}

.ms-Icon--AutoFillTemplate:before {
    content: "\F313";
}

.ms-Icon--AutoFitContents:before {
    content: "\E3E8";
}

.ms-Icon--AutoFitWindow:before {
    content: "\E3E9";
}

.ms-Icon--AutoHeight:before {
    content: "\F512";
}

.ms-Icon--AutomateFlow:before {
    content: "\E3F5";
}

.ms-Icon--AutoRacing:before {
    content: "\EB24";
}

.ms-Icon--AwayStatus:before {
    content: "\EE6A";
}

.ms-Icon--AzureAPIManagement:before {
    content: "\F37F";
}

.ms-Icon--AzureDataExplorer:before {
    content: "\E439";
}

.ms-Icon--AzureIcon:before {
    content: "\EB6A";
}

.ms-Icon--AzureKeyVault:before {
    content: "\F3B4";
}

.ms-Icon--AzureServiceEndpoint:before {
    content: "\F380";
}

.ms-Icon--Back:before {
    content: "\E72B";
}

.ms-Icon--BackgroundColor:before {
    content: "\F42B";
}

.ms-Icon--Backlog:before {
    content: "\F2AC";
}

.ms-Icon--BacklogBoard:before {
    content: "\F444";
}

.ms-Icon--BacklogList:before {
    content: "\F6BF";
}

.ms-Icon--BackToWindow:before {
    content: "\E73F";
}

.ms-Icon--Badge:before {
    content: "\EC1B";
}

.ms-Icon--Balloons:before {
    content: "\ED7E";
}

.ms-Icon--Bank:before {
    content: "\E825";
}

.ms-Icon--BankSolid:before {
    content: "\F34F";
}

.ms-Icon--BarChart4:before {
    content: "\EAE7";
}

.ms-Icon--BarChartHorizontal:before {
    content: "\E9EB";
}

.ms-Icon--BarChartVertical:before {
    content: "\E9EC";
}

.ms-Icon--BarChartVerticalEdit:before {
    content: "\F89D";
}

.ms-Icon--BarChartVerticalFill:before {
    content: "\F830";
}

.ms-Icon--BarChartVerticalFilter:before {
    content: "\F77E";
}

.ms-Icon--BarChartVerticalFilterSolid:before {
    content: "\F77F";
}

.ms-Icon--Baseball:before {
    content: "\EB20";
}

.ms-Icon--BeerMug:before {
    content: "\F49E";
}

.ms-Icon--BIDashboard:before {
    content: "\F543";
}

.ms-Icon--BidiLtr:before {
    content: "\E9AA";
}

.ms-Icon--BidiRtl:before {
    content: "\E9AB";
}

.ms-Icon--BingLogo:before {
    content: "\EB6B";
}

.ms-Icon--BirthdayCake:before {
    content: "\EF8D";
}

.ms-Icon--BlobStorage:before {
    content: "\E436";
}

.ms-Icon--BlockContact:before {
    content: "\E8F8";
}

.ms-Icon--Blocked:before {
    content: "\E733";
}

.ms-Icon--Blocked12:before {
    content: "\F62E";
}

.ms-Icon--Blocked2:before {
    content: "\ECE4";
}

.ms-Icon--Blocked2Solid:before {
    content: "\F737";
}

.ms-Icon--BlockedSite:before {
    content: "\E72F";
}

.ms-Icon--BlockedSiteSolid12:before {
    content: "\F70A";
}

.ms-Icon--BlockedSolid:before {
    content: "\F531";
}

.ms-Icon--Blog:before {
    content: "\F22B";
}

.ms-Icon--BlowingSnow:before {
    content: "\E9C9";
}

.ms-Icon--Blur:before {
    content: "\F28E";
}

.ms-Icon--Boards:before {
    content: "\EF68";
}

.ms-Icon--Bold:before {
    content: "\E8DD";
}

.ms-Icon--BoldBulgarian:before {
    content: "\E5C5";
}

.ms-Icon--BoldF:before {
    content: "\E5B5";
}

.ms-Icon--BoldG:before {
    content: "\E5B3";
}

.ms-Icon--BoldK:before {
    content: "\E5C6";
}

.ms-Icon--BoldKazakh:before {
    content: "\E5C9";
}

.ms-Icon--BoldKorean:before {
    content: "\E5BD";
}

.ms-Icon--BoldN:before {
    content: "\E5B7";
}

.ms-Icon--BoldP:before {
    content: "\E5C7";
}

.ms-Icon--BoldRussion:before {
    content: "\E5B9";
}

.ms-Icon--BoldSerbian:before {
    content: "\E5CA";
}

.ms-Icon--BoldT:before {
    content: "\E5C8";
}

.ms-Icon--BookAnswers:before {
    content: "\F8A4";
}

.ms-Icon--BookingsLogo:before {
    content: "\EDC7";
}

.ms-Icon--BookmarkReport:before {
    content: "\F76B";
}

.ms-Icon--Bookmarks:before {
    content: "\E8A4";
}

.ms-Icon--BookmarksMirrored:before {
    content: "\EA41";
}

.ms-Icon--BorderDash:before {
    content: "\F50A";
}

.ms-Icon--BorderDot:before {
    content: "\F50B";
}

.ms-Icon--BoxAdditionSolid:before {
    content: "\F2D4";
}

.ms-Icon--BoxCheckmarkSolid:before {
    content: "\F2D7";
}

.ms-Icon--BoxMultiplySolid:before {
    content: "\F2D5";
}

.ms-Icon--BoxPlaySolid:before {
    content: "\F2D6";
}

.ms-Icon--BoxSubtractSolid:before {
    content: "\F2D3";
}

.ms-Icon--BranchCommit:before {
    content: "\F293";
}

.ms-Icon--BranchCompare:before {
    content: "\F294";
}

.ms-Icon--BranchFork:before {
    content: "\F173";
}

.ms-Icon--BranchFork2:before {
    content: "\F291";
}

.ms-Icon--BranchLocked:before {
    content: "\F292";
}

.ms-Icon--BranchMerge:before {
    content: "\F295";
}

.ms-Icon--BranchPullRequest:before {
    content: "\F296";
}

.ms-Icon--BranchSearch:before {
    content: "\F297";
}

.ms-Icon--BranchShelveset:before {
    content: "\F298";
}

.ms-Icon--Breadcrumb:before {
    content: "\EF8C";
}

.ms-Icon--Breakfast:before {
    content: "\F49C";
}

.ms-Icon--Brightness:before {
    content: "\E706";
}

.ms-Icon--Broom:before {
    content: "\EA99";
}

.ms-Icon--BrowserScreenShot:before {
    content: "\EBED";
}

.ms-Icon--BrowserTab:before {
    content: "\F5D7";
}

.ms-Icon--BrowserTabScreenshot:before {
    content: "\F5D8";
}

.ms-Icon--Brunch:before {
    content: "\F49D";
}

.ms-Icon--Brush:before {
    content: "\ECFF";
}

.ms-Icon--BucketColor:before {
    content: "\F1B6";
}

.ms-Icon--BucketColorFill:before {
    content: "\F1B7";
}

.ms-Icon--BufferTimeAfter:before {
    content: "\F0D0";
}

.ms-Icon--BufferTimeBefore:before {
    content: "\F0CF";
}

.ms-Icon--BufferTimeBoth:before {
    content: "\F0D1";
}

.ms-Icon--Bug:before {
    content: "\EBE8";
}

.ms-Icon--BugAction:before {
    content: "\E358";
}

.ms-Icon--BugBlock:before {
    content: "\E400";
}

.ms-Icon--BugSolid:before {
    content: "\F335";
}

.ms-Icon--BugSync:before {
    content: "\E3FF";
}

.ms-Icon--BugWarning:before {
    content: "\E357";
}

.ms-Icon--Build:before {
    content: "\F28F";
}

.ms-Icon--BuildDefinition:before {
    content: "\F6E9";
}

.ms-Icon--BuildIssue:before {
    content: "\F319";
}

.ms-Icon--BuildQueue:before {
    content: "\F24F";
}

.ms-Icon--BuildQueueNew:before {
    content: "\F250";
}

.ms-Icon--BulkPageBlock:before {
    content: "\E553";
}

.ms-Icon--BulkUpload:before {
    content: "\F548";
}

.ms-Icon--BulletedList:before {
    content: "\E8FD";
}

.ms-Icon--BulletedList2:before {
    content: "\F2C7";
}

.ms-Icon--BulletedList2Mirrored:before {
    content: "\F2C8";
}

.ms-Icon--BulletedListBullet:before {
    content: "\F793";
}

.ms-Icon--BulletedListBulletMirrored:before {
    content: "\F795";
}

.ms-Icon--BulletedListMirrored:before {
    content: "\EA42";
}

.ms-Icon--BulletedListText:before {
    content: "\F792";
}

.ms-Icon--BulletedListTextMirrored:before {
    content: "\F794";
}

.ms-Icon--BulletedTreeList:before {
    content: "\F84C";
}

.ms-Icon--Bullseye:before {
    content: "\F272";
}

.ms-Icon--BullseyeTarget:before {
    content: "\F5F0";
}

.ms-Icon--BullseyeTargetDelete:before {
    content: "\F6C1";
}

.ms-Icon--BullseyeTargetEdit:before {
    content: "\E319";
}

.ms-Icon--Bus:before {
    content: "\E806";
}

.ms-Icon--BusinessCenterLogo:before {
    content: "\F4B2";
}

.ms-Icon--BusinessHoursSign:before {
    content: "\F310";
}

.ms-Icon--BusinessRule:before {
    content: "\E562";
}

.ms-Icon--BusSolid:before {
    content: "\EB47";
}

.ms-Icon--ButtonControl:before {
    content: "\F6C0";
}

.ms-Icon--Cafe:before {
    content: "\EC32";
}

.ms-Icon--Cake:before {
    content: "\ECA4";
}

.ms-Icon--CalculatedTable:before {
    content: "\E4BE";
}

.ms-Icon--Calculator:before {
    content: "\E8EF";
}

.ms-Icon--CalculatorAddition:before {
    content: "\E948";
}

.ms-Icon--CalculatorDelta:before {
    content: "\E406";
}

.ms-Icon--CalculatorEqualTo:before {
    content: "\E94E";
}

.ms-Icon--CalculatorGroup:before {
    content: "\E462";
}

.ms-Icon--CalculatorMultiply:before {
    content: "\E947";
}

.ms-Icon--CalculatorNotEqualTo:before {
    content: "\F2D2";
}

.ms-Icon--CalculatorPercentage:before {
    content: "\E94C";
}

.ms-Icon--CalculatorSubtract:before {
    content: "\E949";
}

.ms-Icon--Calendar:before {
    content: "\E787";
}

.ms-Icon--CalendarAgenda:before {
    content: "\EE9A";
}

.ms-Icon--CalendarDay:before {
    content: "\E8BF";
}

.ms-Icon--CalendarMirrored:before {
    content: "\ED28";
}

.ms-Icon--CalendarReply:before {
    content: "\E8F5";
}

.ms-Icon--CalendarSettings:before {
    content: "\F558";
}

.ms-Icon--CalendarSettingsMirrored:before {
    content: "\F559";
}

.ms-Icon--CalendarWeek:before {
    content: "\E8C0";
}

.ms-Icon--CalendarWorkWeek:before {
    content: "\EF51";
}

.ms-Icon--CalendarYear:before {
    content: "\E371";
}

.ms-Icon--Calories:before {
    content: "\ECAD";
}

.ms-Icon--CaloriesAdd:before {
    content: "\F172";
}

.ms-Icon--Camera:before {
    content: "\E722";
}

.ms-Icon--CampaignTemplate:before {
    content: "\F811";
}

.ms-Icon--Cancel:before {
    content: "\E711";
}

.ms-Icon--CannedChat:before {
    content: "\F0F2";
}

.ms-Icon--Car:before {
    content: "\E804";
}

.ms-Icon--CareActivity:before {
    content: "\E549";
}

.ms-Icon--CarePlan:before {
    content: "\E54A";
}

.ms-Icon--CaretBottomLeftCenter8:before {
    content: "\F365";
}

.ms-Icon--CaretBottomLeftSolid8:before {
    content: "\F121";
}

.ms-Icon--CaretBottomRightCenter8:before {
    content: "\F364";
}

.ms-Icon--CaretBottomRightSolid8:before {
    content: "\F122";
}

.ms-Icon--CaretDown8:before {
    content: "\EDD8";
}

.ms-Icon--CaretDownSolid8:before {
    content: "\EDDC";
}

.ms-Icon--CaretHollow:before {
    content: "\E817";
}

.ms-Icon--CaretHollowMirrored:before {
    content: "\EA45";
}

.ms-Icon--CaretLeft8:before {
    content: "\EDD5";
}

.ms-Icon--CaretLeftSolid8:before {
    content: "\EDD9";
}

.ms-Icon--CaretRight:before {
    content: "\F06B";
}

.ms-Icon--CaretRight8:before {
    content: "\EDD6";
}

.ms-Icon--CaretRightSolid8:before {
    content: "\EDDA";
}

.ms-Icon--CaretSolid:before {
    content: "\E818";
}

.ms-Icon--CaretSolid16:before {
    content: "\EE62";
}

.ms-Icon--CaretSolidAlt:before {
    content: "\E483";
}

.ms-Icon--CaretSolidDown:before {
    content: "\F08E";
}

.ms-Icon--CaretSolidLeft:before {
    content: "\F08D";
}

.ms-Icon--CaretSolidMirrored:before {
    content: "\EA46";
}

.ms-Icon--CaretSolidRight:before {
    content: "\F08F";
}

.ms-Icon--CaretSolidUp:before {
    content: "\F090";
}

.ms-Icon--CaretTopLeftCenter8:before {
    content: "\F367";
}

.ms-Icon--CaretTopLeftSolid8:before {
    content: "\EF54";
}

.ms-Icon--CaretTopRightCenter8:before {
    content: "\F366";
}

.ms-Icon--CaretTopRightSolid8:before {
    content: "\EF55";
}

.ms-Icon--CaretUp8:before {
    content: "\EDD7";
}

.ms-Icon--CaretUpSolid8:before {
    content: "\EDDB";
}

.ms-Icon--Cat:before {
    content: "\ED7F";
}

.ms-Icon--CategoryClassification:before {
    content: "\E48C";
}

.ms-Icon--CC:before {
    content: "\E7F0";
}

.ms-Icon--CCSolid:before {
    content: "\E4F4";
}

.ms-Icon--CellPhone:before {
    content: "\E8EA";
}

.ms-Icon--Certificate:before {
    content: "\EB95";
}

.ms-Icon--CertifiedDatabase:before {
    content: "\F5BB";
}

.ms-Icon--ChangeEntitlements:before {
    content: "\E310";
}

.ms-Icon--Chart:before {
    content: "\E999";
}

.ms-Icon--ChartSeries:before {
    content: "\F513";
}

.ms-Icon--ChartTemplate:before {
    content: "\F812";
}

.ms-Icon--ChartXAngle:before {
    content: "\F514";
}

.ms-Icon--ChartYAngle:before {
    content: "\F515";
}

.ms-Icon--Chat:before {
    content: "\E901";
}

.ms-Icon--ChatBot:before {
    content: "\F08B";
}

.ms-Icon--ChatInviteFriend:before {
    content: "\ECFE";
}

.ms-Icon--ChatSolid:before {
    content: "\F344";
}

.ms-Icon--Checkbox:before {
    content: "\E739";
}

.ms-Icon--CheckboxComposite:before {
    content: "\E73A";
}

.ms-Icon--CheckboxCompositeReversed:before {
    content: "\E73D";
}

.ms-Icon--CheckboxFill:before {
    content: "\E73B";
}

.ms-Icon--CheckboxIndeterminate:before {
    content: "\E73C";
}

.ms-Icon--CheckboxIndeterminateCombo:before {
    content: "\F16E";
}

.ms-Icon--CheckedOutByOther12:before {
    content: "\F630";
}

.ms-Icon--CheckedOutByYou12:before {
    content: "\F631";
}

.ms-Icon--CheckList:before {
    content: "\E9D5";
}

.ms-Icon--CheckListCheck:before {
    content: "\F7A9";
}

.ms-Icon--CheckListCheckMirrored:before {
    content: "\F7AB";
}

.ms-Icon--CheckListText:before {
    content: "\F7A8";
}

.ms-Icon--CheckListTextMirrored:before {
    content: "\F7AA";
}

.ms-Icon--CheckMark:before {
    content: "\E73E";
}

.ms-Icon--ChevronDown:before {
    content: "\E70D";
}

.ms-Icon--ChevronDownEnd:before {
    content: "\F5E4";
}

.ms-Icon--ChevronDownEnd6:before {
    content: "\F36F";
}

.ms-Icon--ChevronDownMed:before {
    content: "\E972";
}

.ms-Icon--ChevronDownSmall:before {
    content: "\E96E";
}

.ms-Icon--ChevronFold10:before {
    content: "\F36A";
}

.ms-Icon--ChevronLeft:before {
    content: "\E76B";
}

.ms-Icon--ChevronLeftEnd6:before {
    content: "\F371";
}

.ms-Icon--ChevronLeftMed:before {
    content: "\E973";
}

.ms-Icon--ChevronLeftSmall:before {
    content: "\E96F";
}

.ms-Icon--ChevronRight:before {
    content: "\E76C";
}

.ms-Icon--ChevronRightEnd6:before {
    content: "\F372";
}

.ms-Icon--ChevronRightMed:before {
    content: "\E974";
}

.ms-Icon--ChevronRightSmall:before {
    content: "\E970";
}

.ms-Icon--ChevronUnfold10:before {
    content: "\F369";
}

.ms-Icon--ChevronUp:before {
    content: "\E70E";
}

.ms-Icon--ChevronUpEnd:before {
    content: "\E55B";
}

.ms-Icon--ChevronUpEnd6:before {
    content: "\F370";
}

.ms-Icon--ChevronUpMed:before {
    content: "\E971";
}

.ms-Icon--ChevronUpSmall:before {
    content: "\E96D";
}

.ms-Icon--Childof:before {
    content: "\F82D";
}

.ms-Icon--ChoiceColumn:before {
    content: "\E4AE";
}

.ms-Icon--Chopsticks:before {
    content: "\F4A2";
}

.ms-Icon--ChromeBack:before {
    content: "\E830";
}

.ms-Icon--ChromeBackMirrored:before {
    content: "\EA47";
}

.ms-Icon--ChromeClose:before {
    content: "\E8BB";
}

.ms-Icon--ChromeFullScreen:before {
    content: "\E92D";
}

.ms-Icon--ChromeMinimize:before {
    content: "\E921";
}

.ms-Icon--ChromeRestore:before {
    content: "\E923";
}

.ms-Icon--CircleAddition:before {
    content: "\F2E3";
}

.ms-Icon--CircleAdditionSolid:before {
    content: "\F2E4";
}

.ms-Icon--CircleFill:before {
    content: "\EA3B";
}

.ms-Icon--CircleHalfFull:before {
    content: "\ED9E";
}

.ms-Icon--CirclePause:before {
    content: "\F2D9";
}

.ms-Icon--CirclePauseSolid:before {
    content: "\F2D8";
}

.ms-Icon--CirclePlus:before {
    content: "\EAEE";
}

.ms-Icon--CircleRing:before {
    content: "\EA3A";
}

.ms-Icon--CircleShape:before {
    content: "\F1A5";
}

.ms-Icon--CircleShapeSolid:before {
    content: "\F63C";
}

.ms-Icon--CircleStop:before {
    content: "\F2DC";
}

.ms-Icon--CircleStopSolid:before {
    content: "\F2DB";
}

.ms-Icon--CityNext:before {
    content: "\EC06";
}

.ms-Icon--CityNext2:before {
    content: "\EC07";
}

.ms-Icon--ClassNotebookLogo16:before {
    content: "\F488";
}

.ms-Icon--ClassNotebookLogo32:before {
    content: "\F486";
}

.ms-Icon--ClassNotebookLogoFill16:before {
    content: "\F489";
}

.ms-Icon--ClassNotebookLogoFill32:before {
    content: "\F487";
}

.ms-Icon--ClassNotebookLogoInverse:before {
    content: "\EDC8";
}

.ms-Icon--ClassNotebookLogoInverse16:before {
    content: "\F48B";
}

.ms-Icon--ClassNotebookLogoInverse32:before {
    content: "\F48A";
}

.ms-Icon--ClassroomLogo:before {
    content: "\EF75";
}

.ms-Icon--Clear:before {
    content: "\E894";
}

.ms-Icon--ClearFilter:before {
    content: "\EF8F";
}

.ms-Icon--ClearFormatting:before {
    content: "\EDDD";
}

.ms-Icon--ClearFormattingA:before {
    content: "\F79D";
}

.ms-Icon--ClearFormattingEraser:before {
    content: "\F79E";
}

.ms-Icon--ClearNight:before {
    content: "\E9C2";
}

.ms-Icon--ClearSelection:before {
    content: "\E8E6";
}

.ms-Icon--ClearSelectionMirrored:before {
    content: "\EA48";
}

.ms-Icon--Clicked:before {
    content: "\F268";
}

.ms-Icon--ClinicalImpression:before {
    content: "\E54B";
}

.ms-Icon--ClipboardList:before {
    content: "\F0E3";
}

.ms-Icon--ClipboardListAdd:before {
    content: "\E4EF";
}

.ms-Icon--ClipboardListMirrored:before {
    content: "\F0E4";
}

.ms-Icon--ClipboardListQuestion:before {
    content: "\E4F0";
}

.ms-Icon--ClipboardListReply:before {
    content: "\E4F1";
}

.ms-Icon--ClipboardSolid:before {
    content: "\F5DC";
}

.ms-Icon--Clock:before {
    content: "\E917";
}

.ms-Icon--CloneToDesktop:before {
    content: "\F28C";
}

.ms-Icon--ClosedCaption:before {
    content: "\EF84";
}

.ms-Icon--ClosePane:before {
    content: "\E89F";
}

.ms-Icon--ClosePaneMirrored:before {
    content: "\EA49";
}

.ms-Icon--Cloud:before {
    content: "\E753";
}

.ms-Icon--CloudAdd:before {
    content: "\ECA9";
}

.ms-Icon--CloudDownload:before {
    content: "\EBD3";
}

.ms-Icon--CloudEdit:before {
    content: "\E4C8";
}

.ms-Icon--CloudImportExport:before {
    content: "\EE55";
}

.ms-Icon--CloudLink:before {
    content: "\E4C9";
}

.ms-Icon--CloudNotSynced:before {
    content: "\EC9C";
}

.ms-Icon--CloudSearch:before {
    content: "\EDE4";
}

.ms-Icon--CloudSecure:before {
    content: "\E4D5";
}

.ms-Icon--CloudUpload:before {
    content: "\EC8E";
}

.ms-Icon--CloudWeather:before {
    content: "\E9BE";
}

.ms-Icon--Cloudy:before {
    content: "\E9BF";
}

.ms-Icon--Cocktails:before {
    content: "\EA9D";
}

.ms-Icon--Code:before {
    content: "\E943";
}

.ms-Icon--CodeEdit:before {
    content: "\F544";
}

.ms-Icon--Coffee:before {
    content: "\EAEF";
}

.ms-Icon--CoffeeScript:before {
    content: "\F2FA";
}

.ms-Icon--CollapseContent:before {
    content: "\F165";
}

.ms-Icon--CollapseContentSingle:before {
    content: "\F166";
}

.ms-Icon--CollapseMenu:before {
    content: "\EF66";
}

.ms-Icon--CollegeFootball:before {
    content: "\EB26";
}

.ms-Icon--CollegeHoops:before {
    content: "\EB25";
}

.ms-Icon--Color:before {
    content: "\E790";
}

.ms-Icon--ColorSolid:before {
    content: "\F354";
}

.ms-Icon--Column:before {
    content: "\E438";
}

.ms-Icon--ColumnFunction:before {
    content: "\E4C2";
}

.ms-Icon--ColumnLeftTwoThirds:before {
    content: "\F1D6";
}

.ms-Icon--ColumnLeftTwoThirdsEdit:before {
    content: "\F324";
}

.ms-Icon--ColumnOptions:before {
    content: "\F317";
}

.ms-Icon--ColumnQuestion:before {
    content: "\E4C0";
}

.ms-Icon--ColumnQuestionMirrored:before {
    content: "\E4C1";
}

.ms-Icon--ColumnRightTwoThirds:before {
    content: "\F1D7";
}

.ms-Icon--ColumnRightTwoThirdsEdit:before {
    content: "\F325";
}

.ms-Icon--ColumnSigma:before {
    content: "\E4BF";
}

.ms-Icon--ColumnVerticalSection:before {
    content: "\F81E";
}

.ms-Icon--ColumnVerticalSectionEdit:before {
    content: "\F806";
}

.ms-Icon--Combine:before {
    content: "\EDBB";
}

.ms-Icon--Combobox:before {
    content: "\F516";
}

.ms-Icon--CommandPrompt:before {
    content: "\E756";
}

.ms-Icon--Comment:before {
    content: "\E90A";
}

.ms-Icon--CommentActive:before {
    content: "\F804";
}

.ms-Icon--CommentAdd:before {
    content: "\F2B3";
}

.ms-Icon--CommentNext:before {
    content: "\F2B4";
}

.ms-Icon--CommentPrevious:before {
    content: "\F2B5";
}

.ms-Icon--CommentSolid:before {
    content: "\E30E";
}

.ms-Icon--CommentUrgent:before {
    content: "\F307";
}

.ms-Icon--Commitments:before {
    content: "\EC4D";
}

.ms-Icon--CommonDataServiceCDS:before {
    content: "\E377";
}

.ms-Icon--CommunicationDetails:before {
    content: "\E4CF";
}

.ms-Icon--CommunicationDetailsMirrored:before {
    content: "\E4D0";
}

.ms-Icon--Communications:before {
    content: "\E95A";
}

.ms-Icon--CompanyDirectory:before {
    content: "\EF0D";
}

.ms-Icon--CompanyDirectoryMirrored:before {
    content: "\EF2B";
}

.ms-Icon--Compare:before {
    content: "\F057";
}

.ms-Icon--CompareUneven:before {
    content: "\E42E";
}

.ms-Icon--CompassNW:before {
    content: "\E942";
}

.ms-Icon--Completed:before {
    content: "\E930";
}

.ms-Icon--Completed12:before {
    content: "\E559";
}

.ms-Icon--CompletedSolid:before {
    content: "\EC61";
}

.ms-Icon--ComplianceAudit:before {
    content: "\E369";
}

.ms-Icon--ConfigurationSolid:before {
    content: "\F334";
}

.ms-Icon--ConnectContacts:before {
    content: "\EFD4";
}

.ms-Icon--ConnectVirtualMachine:before {
    content: "\EE9D";
}

.ms-Icon--ConstructionCone:before {
    content: "\E98F";
}

.ms-Icon--ConstructionConeSolid:before {
    content: "\F339";
}

.ms-Icon--Contact:before {
    content: "\E77B";
}

.ms-Icon--ContactCard:before {
    content: "\EEBD";
}

.ms-Icon--ContactCardSettings:before {
    content: "\F556";
}

.ms-Icon--ContactCardSettingsMirrored:before {
    content: "\F557";
}

.ms-Icon--ContactHeart:before {
    content: "\F862";
}

.ms-Icon--ContactInfo:before {
    content: "\E779";
}

.ms-Icon--ContactLink:before {
    content: "\F25F";
}

.ms-Icon--ContactList:before {
    content: "\F7E5";
}

.ms-Icon--ContactLock:before {
    content: "\F400";
}

.ms-Icon--ContentFeed:before {
    content: "\E428";
}

.ms-Icon--ContentSettings:before {
    content: "\F647";
}

.ms-Icon--ContentUnderstandingApp:before {
    content: "\E4FB";
}

.ms-Icon--ContextMenu:before {
    content: "\F37C";
}

.ms-Icon--Contrast:before {
    content: "\E7A1";
}

.ms-Icon--Copy:before {
    content: "\E8C8";
}

.ms-Icon--CopyEdit:before {
    content: "\E464";
}

.ms-Icon--CortanaLogoBeckonInner:before {
    content: "\F4C6";
}

.ms-Icon--CortanaLogoBeckonOuter:before {
    content: "\F4C7";
}

.ms-Icon--CortanaLogoInner:before {
    content: "\E832";
}

.ms-Icon--CortanaLogoOuter:before {
    content: "\E831";
}

.ms-Icon--CortanaLogoReadyInner:before {
    content: "\F4C8";
}

.ms-Icon--CortanaLogoReadyOuter:before {
    content: "\F4C9";
}

.ms-Icon--CostContralLedgerAdmin:before {
    content: "\F208";
}

.ms-Icon--CostControl:before {
    content: "\F207";
}

.ms-Icon--Cotton:before {
    content: "\EAF3";
}

.ms-Icon--Coupon:before {
    content: "\F7BC";
}

.ms-Icon--CPlusPlus:before {
    content: "\F2F4";
}

.ms-Icon--CPlusPlusLanguage:before {
    content: "\F2F3";
}

.ms-Icon--CreateMailRule:before {
    content: "\F67A";
}

.ms-Icon--Cricket:before {
    content: "\EB1E";
}

.ms-Icon--CriticalErrorSolid:before {
    content: "\F5C9";
}

.ms-Icon--CRMProcesses:before {
    content: "\EFB1";
}

.ms-Icon--CRMReport:before {
    content: "\EFFE";
}

.ms-Icon--CRMServices:before {
    content: "\EFD2";
}

.ms-Icon--Crop:before {
    content: "\E7A8";
}

.ms-Icon--Crown:before {
    content: "\ED01";
}

.ms-Icon--CrownSolid:before {
    content: "\F336";
}

.ms-Icon--CSharp:before {
    content: "\F2F0";
}

.ms-Icon--CSharpLanguage:before {
    content: "\F2EF";
}

.ms-Icon--CSS:before {
    content: "\EBEF";
}

.ms-Icon--CtrlButton:before {
    content: "\E4B8";
}

.ms-Icon--CubeShape:before {
    content: "\F1AA";
}

.ms-Icon--CubeShapeSolid:before {
    content: "\E421";
}

.ms-Icon--Currency:before {
    content: "\EAF5";
}

.ms-Icon--CustomerAssets:before {
    content: "\F426";
}

.ms-Icon--CustomizeToolbar:before {
    content: "\F828";
}

.ms-Icon--CustomList:before {
    content: "\EEBE";
}

.ms-Icon--CustomListMirrored:before {
    content: "\EEBF";
}

.ms-Icon--Cut:before {
    content: "\E8C6";
}

.ms-Icon--Cycling:before {
    content: "\EAC7";
}

.ms-Icon--D365CoreHR:before {
    content: "\F6BD";
}

.ms-Icon--D365CustomerInsights:before {
    content: "\F3C8";
}

.ms-Icon--D365CustomerVoiceApp:before {
    content: "\E4F7";
}

.ms-Icon--D365ProjectOperations:before {
    content: "\E432";
}

.ms-Icon--D365TalentInsight:before {
    content: "\F6BC";
}

.ms-Icon--D365TalentLearn:before {
    content: "\F6BB";
}

.ms-Icon--DashboardAdd:before {
    content: "\F52D";
}

.ms-Icon--Database:before {
    content: "\EFC7";
}

.ms-Icon--DatabaseSync:before {
    content: "\F842";
}

.ms-Icon--DatabaseView:before {
    content: "\E437";
}

.ms-Icon--DataConnectionLibrary:before {
    content: "\EEB7";
}

.ms-Icon--DataEnrichment:before {
    content: "\E4F5";
}

.ms-Icon--Dataflows:before {
    content: "\F7DD";
}

.ms-Icon--DataflowsLink:before {
    content: "\E366";
}

.ms-Icon--DataManagementSettings:before {
    content: "\EFC8";
}

.ms-Icon--DateTime:before {
    content: "\EC92";
}

.ms-Icon--DateTime12:before {
    content: "\F38F";
}

.ms-Icon--DateTime2:before {
    content: "\EA17";
}

.ms-Icon--DateTimeMirrored:before {
    content: "\EE93";
}

.ms-Icon--DeactivateOrders:before {
    content: "\EFE1";
}

.ms-Icon--Decimals:before {
    content: "\F218";
}

.ms-Icon--DecisionSolid:before {
    content: "\F350";
}

.ms-Icon--DeclineCall:before {
    content: "\F405";
}

.ms-Icon--DecreaseIndent:before {
    content: "\E39B";
}

.ms-Icon--DecreaseIndentArrow:before {
    content: "\F7A3";
}

.ms-Icon--DecreaseIndentArrowMirrored:before {
    content: "\F7A7";
}

.ms-Icon--DecreaseIndentMirrored:before {
    content: "\E39C";
}

.ms-Icon--DecreaseIndentText:before {
    content: "\F7A2";
}

.ms-Icon--DecreaseIndentTextMirrored:before {
    content: "\F7A6";
}

.ms-Icon--DefaultRatio:before {
    content: "\F529";
}

.ms-Icon--DefectSolid:before {
    content: "\F449";
}

.ms-Icon--DefenderApp:before {
    content: "\E83D";
}

.ms-Icon--DefenderBadge12:before {
    content: "\F0FB";
}

.ms-Icon--DefenderTVM:before {
    content: "\F6B3";
}

.ms-Icon--Delete:before {
    content: "\E74D";
}

.ms-Icon--DeleteColumns:before {
    content: "\F64E";
}

.ms-Icon--DeleteRows:before {
    content: "\F64F";
}

.ms-Icon--DeleteRowsMirrored:before {
    content: "\F650";
}

.ms-Icon--DeleteTable:before {
    content: "\F651";
}

.ms-Icon--DeliveryTruck:before {
    content: "\EBF4";
}

.ms-Icon--DelveAnalytics:before {
    content: "\EEEE";
}

.ms-Icon--DelveAnalyticsLogo:before {
    content: "\EDCA";
}

.ms-Icon--DelveLogo:before {
    content: "\F280";
}

.ms-Icon--DelveLogoFill:before {
    content: "\F281";
}

.ms-Icon--DelveLogoInverse:before {
    content: "\ED76";
}

.ms-Icon--DensityComfy:before {
    content: "\E4BA";
}

.ms-Icon--DensityDefault:before {
    content: "\E4B9";
}

.ms-Icon--DependencyAdd:before {
    content: "\E344";
}

.ms-Icon--DependencyRemove:before {
    content: "\E345";
}

.ms-Icon--Deploy:before {
    content: "\F29D";
}

.ms-Icon--Descending:before {
    content: "\EDC1";
}

.ms-Icon--Design:before {
    content: "\EB3C";
}

.ms-Icon--DesktopFlow:before {
    content: "\E4F3";
}

.ms-Icon--DesktopScreenshot:before {
    content: "\F5D9";
}

.ms-Icon--DeveloperTools:before {
    content: "\EC7A";
}

.ms-Icon--DeviceBug:before {
    content: "\E424";
}

.ms-Icon--DeviceOff:before {
    content: "\E402";
}

.ms-Icon--DeviceRun:before {
    content: "\E401";
}

.ms-Icon--Devices2:before {
    content: "\E975";
}

.ms-Icon--Devices3:before {
    content: "\EA6C";
}

.ms-Icon--Devices4:before {
    content: "\EB66";
}

.ms-Icon--Diagnostic:before {
    content: "\E9D9";
}

.ms-Icon--DiagnosticDataBarTooltip:before {
    content: "\F7DF";
}

.ms-Icon--DiagnosticDataViewerApp:before {
    content: "\F568";
}

.ms-Icon--Dialpad:before {
    content: "\E75F";
}

.ms-Icon--Diamond:before {
    content: "\ED02";
}

.ms-Icon--DiamondSolid:before {
    content: "\F34C";
}

.ms-Icon--DiamondUser:before {
    content: "\E4F9";
}

.ms-Icon--Dictionary:before {
    content: "\E82D";
}

.ms-Icon--DictionaryRemove:before {
    content: "\F69A";
}

.ms-Icon--DietPlanNotebook:before {
    content: "\EAC8";
}

.ms-Icon--DiffInline:before {
    content: "\F309";
}

.ms-Icon--DiffSideBySide:before {
    content: "\F30A";
}

.ms-Icon--DisableUpdates:before {
    content: "\E8D8";
}

.ms-Icon--DisconnectVirtualMachine:before {
    content: "\F873";
}

.ms-Icon--Dislike:before {
    content: "\E8E0";
}

.ms-Icon--DislikeSolid:before {
    content: "\F3C0";
}

.ms-Icon--DistributeDown:before {
    content: "\F76A";
}

.ms-Icon--DockLeft:before {
    content: "\E90C";
}

.ms-Icon--DockLeftMirrored:before {
    content: "\EA4C";
}

.ms-Icon--DockRight:before {
    content: "\E90D";
}

.ms-Icon--DocLibrary:before {
    content: "\EEB8";
}

.ms-Icon--DocsLogoInverse:before {
    content: "\EDCB";
}

.ms-Icon--Document:before {
    content: "\E8A5";
}

.ms-Icon--DocumentApproval:before {
    content: "\F28B";
}

.ms-Icon--Documentation:before {
    content: "\EC17";
}

.ms-Icon--DocumentManagement:before {
    content: "\EFFC";
}

.ms-Icon--DocumentReply:before {
    content: "\EF57";
}

.ms-Icon--DocumentSearch:before {
    content: "\EF6C";
}

.ms-Icon--DocumentSet:before {
    content: "\EED6";
}

.ms-Icon--DOM:before {
    content: "\EC8D";
}

.ms-Icon--DonutChart:before {
    content: "\F368";
}

.ms-Icon--Door:before {
    content: "\EB75";
}

.ms-Icon--DoubleBookmark:before {
    content: "\EB8F";
}

.ms-Icon--DoubleChevronDown:before {
    content: "\EE04";
}

.ms-Icon--DoubleChevronDown12:before {
    content: "\EE97";
}

.ms-Icon--DoubleChevronDown8:before {
    content: "\F36B";
}

.ms-Icon--DoubleChevronLeft:before {
    content: "\EDBE";
}

.ms-Icon--DoubleChevronLeft12:before {
    content: "\EE98";
}

.ms-Icon--DoubleChevronLeft8:before {
    content: "\F36D";
}

.ms-Icon--DoubleChevronLeftMed:before {
    content: "\E991";
}

.ms-Icon--DoubleChevronLeftMedMirrored:before {
    content: "\EA4D";
}

.ms-Icon--DoubleChevronRight:before {
    content: "\EDBF";
}

.ms-Icon--DoubleChevronRight12:before {
    content: "\EE99";
}

.ms-Icon--DoubleChevronRight8:before {
    content: "\F36E";
}

.ms-Icon--DoubleChevronUp:before {
    content: "\EDBD";
}

.ms-Icon--DoubleChevronUp12:before {
    content: "\EE96";
}

.ms-Icon--DoubleChevronUp8:before {
    content: "\F36C";
}

.ms-Icon--DoubleColumn:before {
    content: "\F1D4";
}

.ms-Icon--DoubleColumnEdit:before {
    content: "\F322";
}

.ms-Icon--DoubleDownArrow:before {
    content: "\F769";
}

.ms-Icon--Down:before {
    content: "\E74B";
}

.ms-Icon--Download:before {
    content: "\E896";
}

.ms-Icon--DownloadDocument:before {
    content: "\F549";
}

.ms-Icon--DragObject:before {
    content: "\F553";
}

.ms-Icon--DrillDown:before {
    content: "\F532";
}

.ms-Icon--DrillDownSolid:before {
    content: "\F533";
}

.ms-Icon--DrillExpand:before {
    content: "\F534";
}

.ms-Icon--DrillShow:before {
    content: "\F535";
}

.ms-Icon--DrillThrough:before {
    content: "\F5B9";
}

.ms-Icon--DriverOff:before {
    content: "\E3FB";
}

.ms-Icon--DRM:before {
    content: "\ECA8";
}

.ms-Icon--Drop:before {
    content: "\EB42";
}

.ms-Icon--Dropdown:before {
    content: "\EDC5";
}

.ms-Icon--DropShape:before {
    content: "\F1A8";
}

.ms-Icon--DropShapeSolid:before {
    content: "\F63F";
}

.ms-Icon--DuplicateRow:before {
    content: "\F82A";
}

.ms-Icon--Duststorm:before {
    content: "\E9CD";
}

.ms-Icon--DynamicList:before {
    content: "\E491";
}

.ms-Icon--Dynamics365Logo:before {
    content: "\EDCC";
}

.ms-Icon--DynamicSMBLogo:before {
    content: "\EDCD";
}

.ms-Icon--EaseOfAccess:before {
    content: "\E776";
}

.ms-Icon--EatDrink:before {
    content: "\E807";
}

.ms-Icon--EdgeOldLogo:before {
    content: "\EC60";
}

.ms-Icon--eDiscovery:before {
    content: "\E370";
}

.ms-Icon--Edit:before {
    content: "\E70F";
}

.ms-Icon--EditContact:before {
    content: "\EFD3";
}

.ms-Icon--EditCreate:before {
    content: "\F3C9";
}

.ms-Icon--EditEvent:before {
    content: "\F05B";
}

.ms-Icon--EditMail:before {
    content: "\EF61";
}

.ms-Icon--EditMirrored:before {
    content: "\EB7E";
}

.ms-Icon--EditNote:before {
    content: "\ED9D";
}

.ms-Icon--EditPhoto:before {
    content: "\EF77";
}

.ms-Icon--EditSolid12:before {
    content: "\F4B5";
}

.ms-Icon--EditSolidMirrored12:before {
    content: "\F4B6";
}

.ms-Icon--EditStyle:before {
    content: "\EF60";
}

.ms-Icon--EditTable:before {
    content: "\E4C4";
}

.ms-Icon--Education:before {
    content: "\E7BE";
}

.ms-Icon--Ellipse:before {
    content: "\F4FB";
}

.ms-Icon--Embed:before {
    content: "\ECCE";
}

.ms-Icon--EMI:before {
    content: "\E731";
}

.ms-Icon--Emoji:before {
    content: "\E899";
}

.ms-Icon--Emoji2:before {
    content: "\E76E";
}

.ms-Icon--EmojiDisappointed:before {
    content: "\EA88";
}

.ms-Icon--EmojiNeutral:before {
    content: "\EA87";
}

.ms-Icon--EmojiTabSymbols:before {
    content: "\ED58";
}

.ms-Icon--EmptyRecycleBin:before {
    content: "\EF88";
}

.ms-Icon--Encounter:before {
    content: "\E54C";
}

.ms-Icon--Encryption:before {
    content: "\F69D";
}

.ms-Icon--EndPointSolid:before {
    content: "\EB4B";
}

.ms-Icon--EngineeringGroup:before {
    content: "\F362";
}

.ms-Icon--EntitlementPolicy:before {
    content: "\E346";
}

.ms-Icon--EntitlementRedemption:before {
    content: "\E347";
}

.ms-Icon--EntityExtraction:before {
    content: "\E467";
}

.ms-Icon--EntryDecline:before {
    content: "\F555";
}

.ms-Icon--EntryView:before {
    content: "\F554";
}

.ms-Icon--Equalizer:before {
    content: "\E9E9";
}

.ms-Icon--EraseTool:before {
    content: "\E75C";
}

.ms-Icon--Error:before {
    content: "\E783";
}

.ms-Icon--ErrorBadge:before {
    content: "\EA39";
}

.ms-Icon--ErrorBadge12:before {
    content: "\E558";
}

.ms-Icon--Event:before {
    content: "\ECA3";
}

.ms-Icon--Event12:before {
    content: "\F763";
}

.ms-Icon--EventAccepted:before {
    content: "\F422";
}

.ms-Icon--EventDate:before {
    content: "\F059";
}

.ms-Icon--EventDateMissed12:before {
    content: "\F764";
}

.ms-Icon--EventDeclined:before {
    content: "\F425";
}

.ms-Icon--EventInfo:before {
    content: "\ED8B";
}

.ms-Icon--EventTentative:before {
    content: "\F423";
}

.ms-Icon--EventTentativeMirrored:before {
    content: "\F424";
}

.ms-Icon--EventToDoLogo:before {
    content: "\F869";
}

.ms-Icon--ExcelDocument:before {
    content: "\EF73";
}

.ms-Icon--ExcelLogo:before {
    content: "\F1E5";
}

.ms-Icon--ExcelLogo16:before {
    content: "\F397";
}

.ms-Icon--ExcelLogoInverse:before {
    content: "\EC28";
}

.ms-Icon--ExcelLogoInverse16:before {
    content: "\F396";
}

.ms-Icon--ExchangeLogo:before {
    content: "\F284";
}

.ms-Icon--ExchangeLogoInverse:before {
    content: "\ED78";
}

.ms-Icon--ExerciseTracker:before {
    content: "\EACC";
}

.ms-Icon--ExpandMenu:before {
    content: "\EF67";
}

.ms-Icon--ExploreContent:before {
    content: "\ECCD";
}

.ms-Icon--ExploreContentSingle:before {
    content: "\F164";
}

.ms-Icon--ExploreData:before {
    content: "\F5B6";
}

.ms-Icon--Export:before {
    content: "\EDE1";
}

.ms-Icon--ExportMirrored:before {
    content: "\EDE2";
}

.ms-Icon--ExpressRouteCircuits:before {
    content: "\E557";
}

.ms-Icon--ExternalBuild:before {
    content: "\F445";
}

.ms-Icon--ExternalTFVC:before {
    content: "\F446";
}

.ms-Icon--ExternalUser:before {
    content: "\E4CA";
}

.ms-Icon--ExternalXAML:before {
    content: "\F447";
}

.ms-Icon--Eyedropper:before {
    content: "\EF3C";
}

.ms-Icon--EyeShadow:before {
    content: "\F7EB";
}

.ms-Icon--F12DevTools:before {
    content: "\EBEE";
}

.ms-Icon--FabricAssetLibrary:before {
    content: "\F09C";
}

.ms-Icon--FabricChannelFolder:before {
    content: "\E4FA";
}

.ms-Icon--FabricDataConnectionLibrary:before {
    content: "\F09D";
}

.ms-Icon--FabricDocLibrary:before {
    content: "\F09E";
}

.ms-Icon--FabricFolder:before {
    content: "\F0A9";
}

.ms-Icon--FabricFolderConfirm:before {
    content: "\F7FF";
}

.ms-Icon--FabricFolderFill:before {
    content: "\F0AA";
}

.ms-Icon--FabricFolderLink:before {
    content: "\E45C";
}

.ms-Icon--FabricFolderSearch:before {
    content: "\F0A4";
}

.ms-Icon--FabricFolderUpload:before {
    content: "\E3AC";
}

.ms-Icon--FabricFormLibrary:before {
    content: "\F09F";
}

.ms-Icon--FabricFormLibraryMirrored:before {
    content: "\F0A0";
}

.ms-Icon--FabricMovetoFolder:before {
    content: "\F0A5";
}

.ms-Icon--FabricNetworkFolder:before {
    content: "\F5E6";
}

.ms-Icon--FabricNewFolder:before {
    content: "\F0AB";
}

.ms-Icon--FabricOpenFolderHorizontal:before {
    content: "\F0A8";
}

.ms-Icon--FabricPictureLibrary:before {
    content: "\F0AC";
}

.ms-Icon--FabricPublicFolder:before {
    content: "\F0A3";
}

.ms-Icon--FabricReportLibrary:before {
    content: "\F0A1";
}

.ms-Icon--FabricReportLibraryMirrored:before {
    content: "\F0A2";
}

.ms-Icon--FabricSyncFolder:before {
    content: "\F0A7";
}

.ms-Icon--FabricTextHighlight:before {
    content: "\F79C";
}

.ms-Icon--FabricTextHighlightComposite:before {
    content: "\F7DA";
}

.ms-Icon--FabricUnsyncFolder:before {
    content: "\F0A6";
}

.ms-Icon--FabricUserFolder:before {
    content: "\F5E5";
}

.ms-Icon--Family:before {
    content: "\EBDA";
}

.ms-Icon--FangBody:before {
    content: "\ECEB";
}

.ms-Icon--FastForward:before {
    content: "\EB9D";
}

.ms-Icon--FastForwardEightX:before {
    content: "\E443";
}

.ms-Icon--FastForwardFourX:before {
    content: "\E442";
}

.ms-Icon--FastForwardOneFiveX:before {
    content: "\E440";
}

.ms-Icon--FastForwardOneX:before {
    content: "\E43F";
}

.ms-Icon--FastForwardPointFiveX:before {
    content: "\E43E";
}

.ms-Icon--FastForwardTwoX:before {
    content: "\E441";
}

.ms-Icon--FastMode:before {
    content: "\F19A";
}

.ms-Icon--Favicon:before {
    content: "\E737";
}

.ms-Icon--FavoriteList:before {
    content: "\E728";
}

.ms-Icon--FavoriteStar:before {
    content: "\E734";
}

.ms-Icon--FavoriteStarFill:before {
    content: "\E735";
}

.ms-Icon--Fax:before {
    content: "\EF5C";
}

.ms-Icon--Feedback:before {
    content: "\ED15";
}

.ms-Icon--FeedbackRequestMirroredSolid:before {
    content: "\F35A";
}

.ms-Icon--FeedbackRequestSolid:before {
    content: "\F359";
}

.ms-Icon--FeedbackResponseSolid:before {
    content: "\F35B";
}

.ms-Icon--Ferry:before {
    content: "\E7E3";
}

.ms-Icon--FerrySolid:before {
    content: "\EB48";
}

.ms-Icon--FieldChanged:before {
    content: "\F2C3";
}

.ms-Icon--FieldEmpty:before {
    content: "\F2C1";
}

.ms-Icon--FieldFilled:before {
    content: "\F2C2";
}

.ms-Icon--FieldNotChanged:before {
    content: "\F2C4";
}

.ms-Icon--FieldReadOnly:before {
    content: "\F442";
}

.ms-Icon--FieldRequired:before {
    content: "\F443";
}

.ms-Icon--FileASPX:before {
    content: "\F2E9";
}

.ms-Icon--FileBug:before {
    content: "\F30D";
}

.ms-Icon--FileCode:before {
    content: "\F30E";
}

.ms-Icon--FileComment:before {
    content: "\F30F";
}

.ms-Icon--FileCSS:before {
    content: "\F2EA";
}

.ms-Icon--FileHTML:before {
    content: "\F2ED";
}

.ms-Icon--FileImage:before {
    content: "\F311";
}

.ms-Icon--FileJAVA:before {
    content: "\F2E8";
}

.ms-Icon--FileLess:before {
    content: "\F2EC";
}

.ms-Icon--FileOff:before {
    content: "\E3FC";
}

.ms-Icon--FilePDB:before {
    content: "\F2E5";
}

.ms-Icon--FileRequest:before {
    content: "\F789";
}

.ms-Icon--FileSass:before {
    content: "\F2EB";
}

.ms-Icon--FileSQL:before {
    content: "\F2E7";
}

.ms-Icon--FileSymlink:before {
    content: "\F312";
}

.ms-Icon--FileSystem:before {
    content: "\E433";
}

.ms-Icon--FileTemplate:before {
    content: "\F2E6";
}

.ms-Icon--FileTypeSolution:before {
    content: "\F387";
}

.ms-Icon--FileYML:before {
    content: "\F5DA";
}

.ms-Icon--Filter:before {
    content: "\E71C";
}

.ms-Icon--FilterAscending:before {
    content: "\F21A";
}

.ms-Icon--FilterDescending:before {
    content: "\F21B";
}

.ms-Icon--Filters:before {
    content: "\E795";
}

.ms-Icon--FilterSettings:before {
    content: "\F76C";
}

.ms-Icon--FilterSolid:before {
    content: "\F412";
}

.ms-Icon--FiltersSolid:before {
    content: "\F353";
}

.ms-Icon--Financial:before {
    content: "\E7BB";
}

.ms-Icon--FinancialMirroredSolid:before {
    content: "\F347";
}

.ms-Icon--FinancialSolid:before {
    content: "\F346";
}

.ms-Icon--Fingerprint:before {
    content: "\E928";
}

.ms-Icon--FitPage:before {
    content: "\E9A6";
}

.ms-Icon--FitWidth:before {
    content: "\E9A7";
}

.ms-Icon--FiveTileGrid:before {
    content: "\F274";
}

.ms-Icon--FixedAssetManagement:before {
    content: "\EF93";
}

.ms-Icon--FixedColumnWidth:before {
    content: "\E3EA";
}

.ms-Icon--Flag:before {
    content: "\E7C1";
}

.ms-Icon--FlameSolid:before {
    content: "\F1F3";
}

.ms-Icon--FlashAuto:before {
    content: "\E95C";
}

.ms-Icon--Flashlight:before {
    content: "\E754";
}

.ms-Icon--FlashOff:before {
    content: "\EA6E";
}

.ms-Icon--FlickDown:before {
    content: "\E935";
}

.ms-Icon--FlickLeft:before {
    content: "\E937";
}

.ms-Icon--FlickRight:before {
    content: "\E938";
}

.ms-Icon--FlickUp:before {
    content: "\E936";
}

.ms-Icon--Flow:before {
    content: "\EF90";
}

.ms-Icon--FlowChart:before {
    content: "\E9D4";
}

.ms-Icon--Flower:before {
    content: "\F54E";
}

.ms-Icon--FlowTemplate:before {
    content: "\E49C";
}

.ms-Icon--FluidLogo:before {
    content: "\E48A";
}

.ms-Icon--FocalPoint:before {
    content: "\F277";
}

.ms-Icon--Focus:before {
    content: "\EA6F";
}

.ms-Icon--FocusView:before {
    content: "\F1A3";
}

.ms-Icon--Fog:before {
    content: "\E9CB";
}

.ms-Icon--Folder:before {
    content: "\E8B7";
}

.ms-Icon--FolderFill:before {
    content: "\E8D5";
}

.ms-Icon--FolderHorizontal:before {
    content: "\F12B";
}

.ms-Icon--FolderList:before {
    content: "\F2CE";
}

.ms-Icon--FolderListMirrored:before {
    content: "\F2CF";
}

.ms-Icon--FolderOpen:before {
    content: "\E838";
}

.ms-Icon--FolderQuery:before {
    content: "\F2CD";
}

.ms-Icon--FolderSearch:before {
    content: "\EF65";
}

.ms-Icon--FollowUser:before {
    content: "\EE05";
}

.ms-Icon--Font:before {
    content: "\E8D2";
}

.ms-Icon--FontColor:before {
    content: "\E8D3";
}

.ms-Icon--FontColorA:before {
    content: "\F4EC";
}

.ms-Icon--FontColorKorean:before {
    content: "\E5BE";
}

.ms-Icon--FontColorSwatch:before {
    content: "\F4ED";
}

.ms-Icon--FontDecrease:before {
    content: "\E8E7";
}

.ms-Icon--FontIncrease:before {
    content: "\E8E8";
}

.ms-Icon--FontSize:before {
    content: "\E8E9";
}

.ms-Icon--FontSize2:before {
    content: "\E3C0";
}

.ms-Icon--FontStyleKorean:before {
    content: "\E5BA";
}

.ms-Icon--Footer:before {
    content: "\F82E";
}

.ms-Icon--FormatPainter:before {
    content: "\E3DC";
}

.ms-Icon--FormLibrary:before {
    content: "\EEB9";
}

.ms-Icon--FormLibraryMirrored:before {
    content: "\EEBA";
}

.ms-Icon--FormProcessing:before {
    content: "\E48B";
}

.ms-Icon--Forum:before {
    content: "\E378";
}

.ms-Icon--Forward:before {
    content: "\E72A";
}

.ms-Icon--ForwardEvent:before {
    content: "\ED8C";
}

.ms-Icon--Freezing:before {
    content: "\E9EF";
}

.ms-Icon--FreezingRain:before {
    content: "\E475";
}

.ms-Icon--Frigid:before {
    content: "\E9CA";
}

.ms-Icon--FrontCamera:before {
    content: "\E96B";
}

.ms-Icon--FSharp:before {
    content: "\F2F6";
}

.ms-Icon--FSharpLanguage:before {
    content: "\F2F5";
}

.ms-Icon--FullCircleMask:before {
    content: "\E91F";
}

.ms-Icon--FullHistory:before {
    content: "\F31C";
}

.ms-Icon--FullScreen:before {
    content: "\E740";
}

.ms-Icon--FullView:before {
    content: "\F1A2";
}

.ms-Icon--FullWidth:before {
    content: "\F2FE";
}

.ms-Icon--FullWidthEdit:before {
    content: "\F2FF";
}

.ms-Icon--FunctionalManagerDashboard:before {
    content: "\F542";
}

.ms-Icon--FunnelChart:before {
    content: "\E9F1";
}

.ms-Icon--GallatinLogo:before {
    content: "\F496";
}

.ms-Icon--Game:before {
    content: "\E7FC";
}

.ms-Icon--Gather:before {
    content: "\E460";
}

.ms-Icon--Generate:before {
    content: "\E9DA";
}

.ms-Icon--GenericScan:before {
    content: "\EE6F";
}

.ms-Icon--GenericScanFilled:before {
    content: "\F7E3";
}

.ms-Icon--GIF:before {
    content: "\F4A9";
}

.ms-Icon--Giftbox:before {
    content: "\EC1F";
}

.ms-Icon--GiftboxOpen:before {
    content: "\F133";
}

.ms-Icon--GiftBoxSolid:before {
    content: "\F341";
}

.ms-Icon--GiftCard:before {
    content: "\EB8E";
}

.ms-Icon--GitGraph:before {
    content: "\F2CA";
}

.ms-Icon--Glasses:before {
    content: "\EA16";
}

.ms-Icon--Glimmer:before {
    content: "\ECF4";
}

.ms-Icon--GlobalNavButton:before {
    content: "\E700";
}

.ms-Icon--GlobalNavButtonActive:before {
    content: "\F89F";
}

.ms-Icon--Globe:before {
    content: "\E774";
}

.ms-Icon--Globe2:before {
    content: "\F49A";
}

.ms-Icon--GlobeFavorite:before {
    content: "\EF53";
}

.ms-Icon--Go:before {
    content: "\E8AD";
}

.ms-Icon--Golf:before {
    content: "\EB1F";
}

.ms-Icon--GoMirrored:before {
    content: "\EA4F";
}

.ms-Icon--GoToDashboard:before {
    content: "\EEED";
}

.ms-Icon--GotoToday:before {
    content: "\E8D1";
}

.ms-Icon--GraphSymbol:before {
    content: "\E35D";
}

.ms-Icon--GreetingCard:before {
    content: "\F54B";
}

.ms-Icon--GridViewLarge:before {
    content: "\F234";
}

.ms-Icon--GridViewMedium:before {
    content: "\F233";
}

.ms-Icon--GridViewSmall:before {
    content: "\F232";
}

.ms-Icon--GripperBarHorizontal:before {
    content: "\E76F";
}

.ms-Icon--GripperBarVertical:before {
    content: "\E784";
}

.ms-Icon--GripperDotsVertical:before {
    content: "\F772";
}

.ms-Icon--GripperTool:before {
    content: "\E75E";
}

.ms-Icon--Group:before {
    content: "\E902";
}

.ms-Icon--GroupedAscending:before {
    content: "\EE67";
}

.ms-Icon--GroupedDescending:before {
    content: "\EE66";
}

.ms-Icon--GroupedList:before {
    content: "\EF74";
}

.ms-Icon--GroupList:before {
    content: "\F168";
}

.ms-Icon--GroupObject:before {
    content: "\F4F1";
}

.ms-Icon--GroupRemove:before {
    content: "\E495";
}

.ms-Icon--GUID:before {
    content: "\F52B";
}

.ms-Icon--Guitar:before {
    content: "\F49B";
}

.ms-Icon--HailDay:before {
    content: "\EA00";
}

.ms-Icon--HailNight:before {
    content: "\EA13";
}

.ms-Icon--HalfAlpha:before {
    content: "\E97E";
}

.ms-Icon--HalfCircle:before {
    content: "\F501";
}

.ms-Icon--HandsFree:before {
    content: "\EAD0";
}

.ms-Icon--Handwriting:before {
    content: "\E929";
}

.ms-Icon--HardDrive:before {
    content: "\EDA2";
}

.ms-Icon--HardDriveGroup:before {
    content: "\F18F";
}

.ms-Icon--HardDriveLock:before {
    content: "\F55A";
}

.ms-Icon--HardDriveUnlock:before {
    content: "\F55B";
}

.ms-Icon--HazyDay:before {
    content: "\E46B";
}

.ms-Icon--HazyNight:before {
    content: "\E479";
}

.ms-Icon--Header:before {
    content: "\F82F";
}

.ms-Icon--Header1:before {
    content: "\EA19";
}

.ms-Icon--Header2:before {
    content: "\EF36";
}

.ms-Icon--Header3:before {
    content: "\EF37";
}

.ms-Icon--Header4:before {
    content: "\EF38";
}

.ms-Icon--Headset:before {
    content: "\E95B";
}

.ms-Icon--HeadsetSolid:before {
    content: "\F348";
}

.ms-Icon--Health:before {
    content: "\E95E";
}

.ms-Icon--HealthRefresh:before {
    content: "\E3BD";
}

.ms-Icon--HealthSolid:before {
    content: "\F33F";
}

.ms-Icon--Heart:before {
    content: "\EB51";
}

.ms-Icon--HeartBroken:before {
    content: "\EA92";
}

.ms-Icon--HeartFill:before {
    content: "\EB52";
}

.ms-Icon--Help:before {
    content: "\E897";
}

.ms-Icon--HelpMirrored:before {
    content: "\EA51";
}

.ms-Icon--HexaditeInvestigation:before {
    content: "\E3F8";
}

.ms-Icon--HexaditeInvestigationCancel:before {
    content: "\E3F9";
}

.ms-Icon--HexaditeInvestigationSemiAuto:before {
    content: "\E3FA";
}

.ms-Icon--Hexagon:before {
    content: "\F4FE";
}

.ms-Icon--Hide:before {
    content: "\ED1A";
}

.ms-Icon--Hide2:before {
    content: "\EF89";
}

.ms-Icon--Hide3:before {
    content: "\F6AC";
}

.ms-Icon--HideVisualFilter:before {
    content: "\F403";
}

.ms-Icon--Highlight:before {
    content: "\E7E6";
}

.ms-Icon--HighlightMappedShapes:before {
    content: "\F2A1";
}

.ms-Icon--HintText:before {
    content: "\F50F";
}

.ms-Icon--HistoricalWeather:before {
    content: "\EB43";
}

.ms-Icon--History:before {
    content: "\E81C";
}

.ms-Icon--Home:before {
    content: "\E80F";
}

.ms-Icon--HomeDropdown:before {
    content: "\E427";
}

.ms-Icon--HomeGroup:before {
    content: "\EC26";
}

.ms-Icon--HomeSolid:before {
    content: "\EA8A";
}

.ms-Icon--HomeVerify:before {
    content: "\F00E";
}

.ms-Icon--HorizontalDistributeCenter:before {
    content: "\F4F9";
}

.ms-Icon--HorizontalTabKey:before {
    content: "\E7FD";
}

.ms-Icon--Hospital:before {
    content: "\E91D";
}

.ms-Icon--Hot:before {
    content: "\ECE2";
}

.ms-Icon--Hotel:before {
    content: "\E824";
}

.ms-Icon--HourGlass:before {
    content: "\EA03";
}

.ms-Icon--Ice:before {
    content: "\E473";
}

.ms-Icon--IconSetsFlag:before {
    content: "\F2A4";
}

.ms-Icon--IDBadge:before {
    content: "\F427";
}

.ms-Icon--IgnoreConversation:before {
    content: "\E372";
}

.ms-Icon--ImageCrosshair:before {
    content: "\F2C9";
}

.ms-Icon--ImageDiff:before {
    content: "\F30B";
}

.ms-Icon--ImageInAR:before {
    content: "\E420";
}

.ms-Icon--ImagePixel:before {
    content: "\F30C";
}

.ms-Icon--ImageSearch:before {
    content: "\F4E8";
}

.ms-Icon--Import:before {
    content: "\E8B5";
}

.ms-Icon--ImportAllMirrored:before {
    content: "\EA53";
}

.ms-Icon--Important:before {
    content: "\E8C9";
}

.ms-Icon--ImportMirrored:before {
    content: "\EA52";
}

.ms-Icon--Inbox:before {
    content: "\F41C";
}

.ms-Icon--InboxActive:before {
    content: "\E497";
}

.ms-Icon--InboxCheck:before {
    content: "\EF64";
}

.ms-Icon--IncidentTriangle:before {
    content: "\E814";
}

.ms-Icon--IncomingCall:before {
    content: "\E77E";
}

.ms-Icon--IncreaseIndent:before {
    content: "\E399";
}

.ms-Icon--IncreaseIndentArrow:before {
    content: "\F7A1";
}

.ms-Icon--IncreaseIndentArrowMirrored:before {
    content: "\F7A5";
}

.ms-Icon--IncreaseIndentHanging:before {
    content: "\E39D";
}

.ms-Icon--IncreaseIndentHangingMirrored:before {
    content: "\E39E";
}

.ms-Icon--IncreaseIndentMirrored:before {
    content: "\E39A";
}

.ms-Icon--IncreaseIndentText:before {
    content: "\F7A0";
}

.ms-Icon--IncreaseIndentTextMirrored:before {
    content: "\F7A4";
}

.ms-Icon--IndentFirstLine:before {
    content: "\E3DD";
}

.ms-Icon--Info:before {
    content: "\E946";
}

.ms-Icon--Info12:before {
    content: "\E55A";
}

.ms-Icon--Info2:before {
    content: "\EA1F";
}

.ms-Icon--InformationBarriers:before {
    content: "\F803";
}

.ms-Icon--InfoSolid:before {
    content: "\F167";
}

.ms-Icon--InkingTool:before {
    content: "\E76D";
}

.ms-Icon--InputAddress:before {
    content: "\E41E";
}

.ms-Icon--Insert:before {
    content: "\F278";
}

.ms-Icon--InsertColumnsLeft:before {
    content: "\F64A";
}

.ms-Icon--InsertColumnsRight:before {
    content: "\F64B";
}

.ms-Icon--InsertRowsAbove:before {
    content: "\F64C";
}

.ms-Icon--InsertRowsBelow:before {
    content: "\F64D";
}

.ms-Icon--InsertSignatureLine:before {
    content: "\F677";
}

.ms-Icon--InsertTextBox:before {
    content: "\EC7D";
}

.ms-Icon--InsertTextBoxKorean:before {
    content: "\E5D3";
}

.ms-Icon--Insights:before {
    content: "\E3AF";
}

.ms-Icon--Installation:before {
    content: "\E311";
}

.ms-Icon--InstallToDrive:before {
    content: "\F28D";
}

.ms-Icon--IntermittentCloudsDay:before {
    content: "\E46A";
}

.ms-Icon--IntermittentCloudsNight:before {
    content: "\E478";
}

.ms-Icon--InternalInvestigation:before {
    content: "\F854";
}

.ms-Icon--InternetSharing:before {
    content: "\E704";
}

.ms-Icon--IOT:before {
    content: "\F22C";
}

.ms-Icon--IoTSecure:before {
    content: "\E4D6";
}

.ms-Icon--IRMForward:before {
    content: "\F41F";
}

.ms-Icon--IRMForwardMirrored:before {
    content: "\F420";
}

.ms-Icon--IRMReply:before {
    content: "\F41D";
}

.ms-Icon--IRMReplyMirrored:before {
    content: "\F41E";
}

.ms-Icon--IssueSolid:before {
    content: "\F448";
}

.ms-Icon--IssueTracking:before {
    content: "\EEC0";
}

.ms-Icon--IssueTrackingMirrored:before {
    content: "\EEC1";
}

.ms-Icon--Italic:before {
    content: "\E8DB";
}

.ms-Icon--ItalicC:before {
    content: "\E5B2";
}

.ms-Icon--ItalicD:before {
    content: "\E5CE";
}

.ms-Icon--ItalicK:before {
    content: "\E5B6";
}

.ms-Icon--ItalicKazakh:before {
    content: "\E5D2";
}

.ms-Icon--ItalicKorean:before {
    content: "\E5BC";
}

.ms-Icon--ItalicL:before {
    content: "\E5D1";
}

.ms-Icon--ItalicS:before {
    content: "\E5CF";
}

.ms-Icon--ItalicT:before {
    content: "\E5D0";
}

.ms-Icon--JavaScriptLanguage:before {
    content: "\F2EE";
}

.ms-Icon--JoinOnlineMeeting:before {
    content: "\ED8F";
}

.ms-Icon--JS:before {
    content: "\EBF0";
}

.ms-Icon--KaizalaLogo:before {
    content: "\F492";
}

.ms-Icon--KeyboardClassic:before {
    content: "\E765";
}

.ms-Icon--KeyPhraseExtraction:before {
    content: "\E395";
}

.ms-Icon--KnowledgeArticle:before {
    content: "\F000";
}

.ms-Icon--KnowledgeManagementApp:before {
    content: "\E4FC";
}

.ms-Icon--Label:before {
    content: "\E932";
}

.ms-Icon--LadybugSolid:before {
    content: "\F44A";
}

.ms-Icon--Lamp:before {
    content: "\EB19";
}

.ms-Icon--LandscapeOrientation:before {
    content: "\EF6B";
}

.ms-Icon--LaptopSecure:before {
    content: "\F552";
}

.ms-Icon--LaptopSelected:before {
    content: "\EC76";
}

.ms-Icon--LargeGrid:before {
    content: "\EECB";
}

.ms-Icon--LearningApp:before {
    content: "\E5D8";
}

.ms-Icon--LearningTools:before {
    content: "\F7DB";
}

.ms-Icon--Leave:before {
    content: "\F627";
}

.ms-Icon--LeaveUser:before {
    content: "\E3A8";
}

.ms-Icon--Library:before {
    content: "\E8F1";
}

.ms-Icon--Lifesaver:before {
    content: "\EF62";
}

.ms-Icon--LifesaverLock:before {
    content: "\EF63";
}

.ms-Icon--Light:before {
    content: "\E793";
}

.ms-Icon--Lightbulb:before {
    content: "\EA80";
}

.ms-Icon--LightningBolt:before {
    content: "\E945";
}

.ms-Icon--LightningBoltSolid:before {
    content: "\E45F";
}

.ms-Icon--LightningSecure:before {
    content: "\E4D3";
}

.ms-Icon--LightSnow:before {
    content: "\EA02";
}

.ms-Icon--LightWeight:before {
    content: "\F4EE";
}

.ms-Icon--Like:before {
    content: "\E8E1";
}

.ms-Icon--LikeSolid:before {
    content: "\F3BF";
}

.ms-Icon--Line:before {
    content: "\F4FC";
}

.ms-Icon--LineChart:before {
    content: "\E9E6";
}

.ms-Icon--LineSpacing:before {
    content: "\F517";
}

.ms-Icon--LineStyle:before {
    content: "\F50C";
}

.ms-Icon--LineThickness:before {
    content: "\F50D";
}

.ms-Icon--Link:before {
    content: "\E71B";
}

.ms-Icon--Link12:before {
    content: "\F6E3";
}

.ms-Icon--LinkedDatabase:before {
    content: "\F779";
}

.ms-Icon--LinkedInLogo:before {
    content: "\F20A";
}

.ms-Icon--List:before {
    content: "\EA37";
}

.ms-Icon--ListMirrored:before {
    content: "\EA55";
}

.ms-Icon--LiveSite:before {
    content: "\F6A6";
}

.ms-Icon--LocalAdmin:before {
    content: "\F1FB";
}

.ms-Icon--LocaleLanguage:before {
    content: "\F2B7";
}

.ms-Icon--Location:before {
    content: "\E81D";
}

.ms-Icon--LocationCircle:before {
    content: "\E80E";
}

.ms-Icon--LocationDot:before {
    content: "\E827";
}

.ms-Icon--LocationFill:before {
    content: "\E920";
}

.ms-Icon--LocationOutline:before {
    content: "\F2D0";
}

.ms-Icon--Lock:before {
    content: "\E72E";
}

.ms-Icon--Lock12:before {
    content: "\F6E6";
}

.ms-Icon--LockShare:before {
    content: "\E455";
}

.ms-Icon--LockSolid:before {
    content: "\E9A2";
}

.ms-Icon--LogRemove:before {
    content: "\F316";
}

.ms-Icon--LookupEntities:before {
    content: "\F5B5";
}

.ms-Icon--LowerBrightness:before {
    content: "\EC8A";
}

.ms-Icon--LyncLogo:before {
    content: "\ED79";
}

.ms-Icon--M365InvoicingLogo:before {
    content: "\F7C1";
}

.ms-Icon--MachineLearning:before {
    content: "\E3B8";
}

.ms-Icon--Mail:before {
    content: "\E715";
}

.ms-Icon--MailAlert:before {
    content: "\ED80";
}

.ms-Icon--MailAttached:before {
    content: "\F774";
}

.ms-Icon--MailCheck:before {
    content: "\ED81";
}

.ms-Icon--MailFill:before {
    content: "\E8A8";
}

.ms-Icon--MailForward:before {
    content: "\E89C";
}

.ms-Icon--MailForwardMirrored:before {
    content: "\EA56";
}

.ms-Icon--MailLink:before {
    content: "\EFAC";
}

.ms-Icon--MailLowImportance:before {
    content: "\ED82";
}

.ms-Icon--MailOptions:before {
    content: "\F82C";
}

.ms-Icon--MailPause:before {
    content: "\ED83";
}

.ms-Icon--MailReminder:before {
    content: "\F418";
}

.ms-Icon--MailRepeat:before {
    content: "\ED84";
}

.ms-Icon--MailReply:before {
    content: "\E8CA";
}

.ms-Icon--MailReplyAll:before {
    content: "\E8C2";
}

.ms-Icon--MailReplyAllMirrored:before {
    content: "\EA58";
}

.ms-Icon--MailReplyMirrored:before {
    content: "\EA57";
}

.ms-Icon--MailSchedule:before {
    content: "\F72E";
}

.ms-Icon--MailSecure:before {
    content: "\E4D4";
}

.ms-Icon--MailSolid:before {
    content: "\F343";
}

.ms-Icon--MailTentative:before {
    content: "\F416";
}

.ms-Icon--MailTentativeMirrored:before {
    content: "\F417";
}

.ms-Icon--MailUndelivered:before {
    content: "\F415";
}

.ms-Icon--ManagerSelfService:before {
    content: "\EE23";
}

.ms-Icon--Manufacturing:before {
    content: "\E99C";
}

.ms-Icon--MapDirections:before {
    content: "\E816";
}

.ms-Icon--MapLayers:before {
    content: "\E81E";
}

.ms-Icon--MapPin:before {
    content: "\E707";
}

.ms-Icon--MapPin12:before {
    content: "\E3AE";
}

.ms-Icon--MapPinSolid:before {
    content: "\F52E";
}

.ms-Icon--MarkAsProtected:before {
    content: "\F6AE";
}

.ms-Icon--MarkDownLanguage:before {
    content: "\F2FB";
}

.ms-Icon--Market:before {
    content: "\EAFC";
}

.ms-Icon--MarketDown:before {
    content: "\EF42";
}

.ms-Icon--MasterDatabase:before {
    content: "\F5BA";
}

.ms-Icon--MaximumValue:before {
    content: "\F5BC";
}

.ms-Icon--Medal:before {
    content: "\EE38";
}

.ms-Icon--MedalSolid:before {
    content: "\F6B9";
}

.ms-Icon--Media:before {
    content: "\EA69";
}

.ms-Icon--MediaAdd:before {
    content: "\F510";
}

.ms-Icon--Medical:before {
    content: "\EAD4";
}

.ms-Icon--MedicalCare:before {
    content: "\E54D";
}

.ms-Icon--MedicationAdmin:before {
    content: "\E54E";
}

.ms-Icon--MedicationRequest:before {
    content: "\E54F";
}

.ms-Icon--Megaphone:before {
    content: "\E789";
}

.ms-Icon--MegaphoneSolid:before {
    content: "\F332";
}

.ms-Icon--Memo:before {
    content: "\E77C";
}

.ms-Icon--Merge:before {
    content: "\E7D5";
}

.ms-Icon--MergeDuplicate:before {
    content: "\F29A";
}

.ms-Icon--Message:before {
    content: "\E8BD";
}

.ms-Icon--MessageFill:before {
    content: "\EC70";
}

.ms-Icon--MessageFriendRequest:before {
    content: "\F055";
}

.ms-Icon--MetricsFailure:before {
    content: "\E4CE";
}

.ms-Icon--MetricsInstall:before {
    content: "\E4CD";
}

.ms-Icon--MetricsUsage:before {
    content: "\E4CC";
}

.ms-Icon--MicOff:before {
    content: "\EC54";
}

.ms-Icon--MicOff2:before {
    content: "\F781";
}

.ms-Icon--Microphone:before {
    content: "\E720";
}

.ms-Icon--MicrosoftStaffhubLogo:before {
    content: "\F130";
}

.ms-Icon--MicrosoftTranslatorLogo:before {
    content: "\F782";
}

.ms-Icon--MicrosoftTranslatorLogoBlue:before {
    content: "\F853";
}

.ms-Icon--MicrosoftTranslatorLogoGreen:before {
    content: "\F852";
}

.ms-Icon--MiniContract:before {
    content: "\E93B";
}

.ms-Icon--MiniContractMirrored:before {
    content: "\EA59";
}

.ms-Icon--MiniExpand:before {
    content: "\E93A";
}

.ms-Icon--MiniExpandMirrored:before {
    content: "\EA5A";
}

.ms-Icon--MiniLink:before {
    content: "\E732";
}

.ms-Icon--MinimumValue:before {
    content: "\F5BD";
}

.ms-Icon--MobileAngled:before {
    content: "\E463";
}

.ms-Icon--MobileReport:before {
    content: "\F18A";
}

.ms-Icon--MobileSelected:before {
    content: "\EC75";
}

.ms-Icon--ModelingView:before {
    content: "\F871";
}

.ms-Icon--Money:before {
    content: "\EAFD";
}

.ms-Icon--More:before {
    content: "\E712";
}

.ms-Icon--MoreSports:before {
    content: "\EB22";
}

.ms-Icon--MoreVertical:before {
    content: "\F2BC";
}

.ms-Icon--MostyClearNight:before {
    content: "\E476";
}

.ms-Icon--MostyCloudyFlurriesDay:before {
    content: "\E471";
}

.ms-Icon--MostyCloudyFlurriesNight:before {
    content: "\E47D";
}

.ms-Icon--MostyCloudyShowersDay:before {
    content: "\E46D";
}

.ms-Icon--MostyCloudyTStormsDay:before {
    content: "\E46F";
}

.ms-Icon--MostyCloudyTStormsNight:before {
    content: "\E47B";
}

.ms-Icon--MostySunnyDay:before {
    content: "\E468";
}

.ms-Icon--MountainClimbing:before {
    content: "\F6DB";
}

.ms-Icon--Move:before {
    content: "\E7C2";
}

.ms-Icon--Movers:before {
    content: "\EBCD";
}

.ms-Icon--MoveToFolder:before {
    content: "\E8DE";
}

.ms-Icon--MSNLogo:before {
    content: "\EB6C";
}

.ms-Icon--MSNVideos:before {
    content: "\EB1C";
}

.ms-Icon--MSNVideosSolid:before {
    content: "\F2DA";
}

.ms-Icon--MSNVolume:before {
    content: "\EB15";
}

.ms-Icon--MultiSelect:before {
    content: "\E762";
}

.ms-Icon--MultiSelectMirrored:before {
    content: "\EA98";
}

.ms-Icon--MusicInCollection:before {
    content: "\E940";
}

.ms-Icon--MusicInCollectionFill:before {
    content: "\EA36";
}

.ms-Icon--MusicNote:before {
    content: "\EC4F";
}

.ms-Icon--MuteChat:before {
    content: "\F17A";
}

.ms-Icon--MyMoviesTV:before {
    content: "\EE6C";
}

.ms-Icon--MyNetwork:before {
    content: "\EC27";
}

.ms-Icon--Nav2DMapView:before {
    content: "\E800";
}

.ms-Icon--NavigateBack:before {
    content: "\F2DD";
}

.ms-Icon--NavigateBackMirrored:before {
    content: "\F2DE";
}

.ms-Icon--NavigateExternalInline:before {
    content: "\F35F";
}

.ms-Icon--NavigateForward:before {
    content: "\F2DF";
}

.ms-Icon--NavigateForwardMirrored:before {
    content: "\F2E0";
}

.ms-Icon--NavigationFlipper:before {
    content: "\F51D";
}

.ms-Icon--NetworkDeviceScanning:before {
    content: "\E4F6";
}

.ms-Icon--NetworkTower:before {
    content: "\EC05";
}

.ms-Icon--NewAnalyticsQuery:before {
    content: "\F1E0";
}

.ms-Icon--NewFolder:before {
    content: "\E8F4";
}

.ms-Icon--NewMail:before {
    content: "\F7EA";
}

.ms-Icon--News:before {
    content: "\E900";
}

.ms-Icon--NewsSearch:before {
    content: "\F4E9";
}

.ms-Icon--NewTeamProject:before {
    content: "\F2B2";
}

.ms-Icon--Next:before {
    content: "\E893";
}

.ms-Icon--NormalWeight:before {
    content: "\F4EF";
}

.ms-Icon--NoteForward:before {
    content: "\ED99";
}

.ms-Icon--NotePinned:before {
    content: "\ED9A";
}

.ms-Icon--NoteReply:before {
    content: "\ED98";
}

.ms-Icon--NotExecuted:before {
    content: "\F440";
}

.ms-Icon--NotImpactedSolid:before {
    content: "\F441";
}

.ms-Icon--NugetLogo:before {
    content: "\F44C";
}

.ms-Icon--NUIFace:before {
    content: "\EB68";
}

.ms-Icon--Number:before {
    content: "\F691";
}

.ms-Icon--NumberedList:before {
    content: "\EA1C";
}

.ms-Icon--NumberedListMirrored:before {
    content: "\E398";
}

.ms-Icon--NumberedListNumber:before {
    content: "\F797";
}

.ms-Icon--NumberedListNumberMirrored:before {
    content: "\F799";
}

.ms-Icon--NumberedListText:before {
    content: "\F796";
}

.ms-Icon--NumberedListTextMirrored:before {
    content: "\F798";
}

.ms-Icon--NumberField:before {
    content: "\EDC4";
}

.ms-Icon--NumberSequence:before {
    content: "\F52A";
}

.ms-Icon--NumberSymbol:before {
    content: "\F7AC";
}

.ms-Icon--Oauth:before {
    content: "\E5C3";
}

.ms-Icon--ObjectRecognition:before {
    content: "\E4EE";
}

.ms-Icon--Octagon:before {
    content: "\F4FD";
}

.ms-Icon--ODLink:before {
    content: "\E4BB";
}

.ms-Icon--ODLink12:before {
    content: "\E4BC";
}

.ms-Icon--OEM:before {
    content: "\E74C";
}

.ms-Icon--OfficeAddinsLogo:before {
    content: "\EEC7";
}

.ms-Icon--OfficeAssistantLogo:before {
    content: "\EDCE";
}

.ms-Icon--OfficeCatchUp:before {
    content: "\E490";
}

.ms-Icon--OfficeChat:before {
    content: "\F70F";
}

.ms-Icon--OfficeChatSolid:before {
    content: "\F710";
}

.ms-Icon--OfficeFormsLogo:before {
    content: "\F434";
}

.ms-Icon--OfficeFormsLogo16:before {
    content: "\F436";
}

.ms-Icon--OfficeFormsLogo24:before {
    content: "\F43B";
}

.ms-Icon--OfficeFormsLogoInverse:before {
    content: "\EF86";
}

.ms-Icon--OfficeFormsLogoInverse16:before {
    content: "\F433";
}

.ms-Icon--OfficeFormsLogoInverse24:before {
    content: "\F43A";
}

.ms-Icon--OfficeLogo:before {
    content: "\EB6E";
}

.ms-Icon--OfficeStoreLogo:before {
    content: "\EDCF";
}

.ms-Icon--OfficeVideoLogo:before {
    content: "\F282";
}

.ms-Icon--OfficeVideoLogoFill:before {
    content: "\F283";
}

.ms-Icon--OfficeVideoLogoInverse:before {
    content: "\ED7A";
}

.ms-Icon--OfflineOneDriveParachute:before {
    content: "\EEC8";
}

.ms-Icon--OfflineOneDriveParachuteDisabled:before {
    content: "\EEC9";
}

.ms-Icon--OfflineStorage:before {
    content: "\EC8C";
}

.ms-Icon--OfflineStorageSolid:before {
    content: "\F34E";
}

.ms-Icon--Onboarding:before {
    content: "\F3BA";
}

.ms-Icon--OneDriveAdd:before {
    content: "\EF32";
}

.ms-Icon--OneDriveFolder16:before {
    content: "\F53B";
}

.ms-Icon--OneDriveLogo:before {
    content: "\E941";
}

.ms-Icon--OneNoteDocType:before {
    content: "\F04F";
}

.ms-Icon--OneNoteEduLogoInverse:before {
    content: "\EDD0";
}

.ms-Icon--OneNoteLogo:before {
    content: "\F1E7";
}

.ms-Icon--OneNoteLogo16:before {
    content: "\F39A";
}

.ms-Icon--OneNoteLogoInverse:before {
    content: "\EC0D";
}

.ms-Icon--OneNoteLogoInverse16:before {
    content: "\F399";
}

.ms-Icon--OpenEnrollment:before {
    content: "\EF1C";
}

.ms-Icon--OpenFile:before {
    content: "\E8E5";
}

.ms-Icon--OpenFolderHorizontal:before {
    content: "\ED25";
}

.ms-Icon--OpenInNewTab:before {
    content: "\F6AB";
}

.ms-Icon--OpenInNewWindow:before {
    content: "\E8A7";
}

.ms-Icon--OpenPane:before {
    content: "\E8A0";
}

.ms-Icon--OpenPaneMirrored:before {
    content: "\EA5B";
}

.ms-Icon--OpenSource:before {
    content: "\EBC2";
}

.ms-Icon--OpenWith:before {
    content: "\E7AC";
}

.ms-Icon--OpenWithMirrored:before {
    content: "\EA5C";
}

.ms-Icon--OrderLock:before {
    content: "\E4CB";
}

.ms-Icon--Org:before {
    content: "\ECA6";
}

.ms-Icon--Orientation:before {
    content: "\E8B4";
}

.ms-Icon--Orientation2:before {
    content: "\F7E1";
}

.ms-Icon--OutlookLogo:before {
    content: "\F1E9";
}

.ms-Icon--OutlookLogo16:before {
    content: "\F39D";
}

.ms-Icon--OutlookLogoInverse:before {
    content: "\EB6D";
}

.ms-Icon--OutlookLogoInverse16:before {
    content: "\F39C";
}

.ms-Icon--OutlookSpacesBucket:before {
    content: "\E481";
}

.ms-Icon--OutOfOffice:before {
    content: "\ED34";
}

.ms-Icon--Package:before {
    content: "\E7B8";
}

.ms-Icon--Packages:before {
    content: "\F318";
}

.ms-Icon--Padding:before {
    content: "\F518";
}

.ms-Icon--PaddingBottom:before {
    content: "\F51A";
}

.ms-Icon--PaddingLeft:before {
    content: "\F51B";
}

.ms-Icon--PaddingRight:before {
    content: "\F51C";
}

.ms-Icon--PaddingTop:before {
    content: "\F519";
}

.ms-Icon--Page:before {
    content: "\E7C3";
}

.ms-Icon--PageAdd:before {
    content: "\EA1A";
}

.ms-Icon--PageArrowRight:before {
    content: "\EFB8";
}

.ms-Icon--PageBlock:before {
    content: "\EFB5";
}

.ms-Icon--PageCheckedin:before {
    content: "\F104";
}

.ms-Icon--PageCheckedOut:before {
    content: "\F02C";
}

.ms-Icon--PageData:before {
    content: "\E31C";
}

.ms-Icon--PageEdit:before {
    content: "\EFB6";
}

.ms-Icon--PageHeader:before {
    content: "\ECEE";
}

.ms-Icon--PageHeaderEdit:before {
    content: "\E31D";
}

.ms-Icon--PageLeft:before {
    content: "\E760";
}

.ms-Icon--PageLink:before {
    content: "\E302";
}

.ms-Icon--PageList:before {
    content: "\F106";
}

.ms-Icon--PageListFilter:before {
    content: "\F813";
}

.ms-Icon--PageListMirroredSolid:before {
    content: "\F33B";
}

.ms-Icon--PageListSolid:before {
    content: "\F33A";
}

.ms-Icon--PageLock:before {
    content: "\F43F";
}

.ms-Icon--PageRemove:before {
    content: "\EFBA";
}

.ms-Icon--PageRight:before {
    content: "\E761";
}

.ms-Icon--PageShared:before {
    content: "\F02D";
}

.ms-Icon--PageSolid:before {
    content: "\E729";
}

.ms-Icon--PanoIndicator:before {
    content: "\E7B0";
}

.ms-Icon--Parachute:before {
    content: "\F351";
}

.ms-Icon--ParachuteSolid:before {
    content: "\F352";
}

.ms-Icon--Parameter:before {
    content: "\F306";
}

.ms-Icon--ParkingLocation:before {
    content: "\E811";
}

.ms-Icon--ParkingLocationMirrored:before {
    content: "\EA5E";
}

.ms-Icon--ParkingMirroredSolid:before {
    content: "\F34B";
}

.ms-Icon--ParkingSolid:before {
    content: "\F34A";
}

.ms-Icon--PartlyClearNight:before {
    content: "\E477";
}

.ms-Icon--PartlyCloudyDay:before {
    content: "\E9C0";
}

.ms-Icon--PartlyCloudyNight:before {
    content: "\E9C1";
}

.ms-Icon--PartlySunnyDay:before {
    content: "\E469";
}

.ms-Icon--PartlySunnyFlurriesDay:before {
    content: "\E472";
}

.ms-Icon--PartlySunnyShowersDay:before {
    content: "\E46E";
}

.ms-Icon--PartlySunnyShowersNight:before {
    content: "\E47A";
}

.ms-Icon--PartlySunnyTStormsDay:before {
    content: "\E470";
}

.ms-Icon--PartlySunnyTStormsNight:before {
    content: "\E47C";
}

.ms-Icon--PartyLeader:before {
    content: "\ECA7";
}

.ms-Icon--PassiveAuthentication:before {
    content: "\F32A";
}

.ms-Icon--PasswordField:before {
    content: "\F6AA";
}

.ms-Icon--Paste:before {
    content: "\E77F";
}

.ms-Icon--PasteAsCode:before {
    content: "\F5D6";
}

.ms-Icon--PasteAsText:before {
    content: "\F5D5";
}

.ms-Icon--Pause:before {
    content: "\E769";
}

.ms-Icon--PaymentCard:before {
    content: "\E8C7";
}

.ms-Icon--PBIAnomaliesMarker:before {
    content: "\E554";
}

.ms-Icon--PBIAnomaly:before {
    content: "\E548";
}

.ms-Icon--PBIDirectQuery:before {
    content: "\E4E9";
}

.ms-Icon--PBIDual:before {
    content: "\E4EA";
}

.ms-Icon--PBIImport:before {
    content: "\E4EB";
}

.ms-Icon--PBILiveConnect:before {
    content: "\E4EC";
}

.ms-Icon--PC1:before {
    content: "\E977";
}

.ms-Icon--PDF:before {
    content: "\EA90";
}

.ms-Icon--PencilReply:before {
    content: "\EF7B";
}

.ms-Icon--Pentagon:before {
    content: "\F4FF";
}

.ms-Icon--PenWorkspace:before {
    content: "\EDC6";
}

.ms-Icon--People:before {
    content: "\E716";
}

.ms-Icon--PeopleAdd:before {
    content: "\EA15";
}

.ms-Icon--PeopleAlert:before {
    content: "\ED93";
}

.ms-Icon--PeopleBlock:before {
    content: "\ED91";
}

.ms-Icon--PeoplePause:before {
    content: "\ED94";
}

.ms-Icon--PeopleRepeat:before {
    content: "\ED92";
}

.ms-Icon--Permissions:before {
    content: "\E8D7";
}

.ms-Icon--PermissionsSolid:before {
    content: "\F349";
}

.ms-Icon--Personalize:before {
    content: "\E771";
}

.ms-Icon--Phishing:before {
    content: "\F679";
}

.ms-Icon--PhishingCampaign:before {
    content: "\E48D";
}

.ms-Icon--PhishingHook:before {
    content: "\E48E";
}

.ms-Icon--Phone:before {
    content: "\E717";
}

.ms-Icon--Photo:before {
    content: "\E91B";
}

.ms-Icon--Photo2:before {
    content: "\EB9F";
}

.ms-Icon--Photo2Add:before {
    content: "\ECAB";
}

.ms-Icon--Photo2Fill:before {
    content: "\F79F";
}

.ms-Icon--Photo2Remove:before {
    content: "\ECAC";
}

.ms-Icon--PhotoBlock:before {
    content: "\E4C3";
}

.ms-Icon--PhotoCollection:before {
    content: "\E7AA";
}

.ms-Icon--PhotoError:before {
    content: "\E3F7";
}

.ms-Icon--PhotoVideoMedia:before {
    content: "\F0B1";
}

.ms-Icon--Picture:before {
    content: "\E8B9";
}

.ms-Icon--PictureCenter:before {
    content: "\F522";
}

.ms-Icon--PictureFill:before {
    content: "\F523";
}

.ms-Icon--PictureLibrary:before {
    content: "\EEC2";
}

.ms-Icon--PicturePosition:before {
    content: "\F524";
}

.ms-Icon--PictureStretch:before {
    content: "\F525";
}

.ms-Icon--PictureTile:before {
    content: "\F526";
}

.ms-Icon--PieDouble:before {
    content: "\EB04";
}

.ms-Icon--PieSingle:before {
    content: "\EB05";
}

.ms-Icon--PieSingleSolid:before {
    content: "\F530";
}

.ms-Icon--Pill:before {
    content: "\EACB";
}

.ms-Icon--Pin:before {
    content: "\E718";
}

.ms-Icon--Pinned:before {
    content: "\E840";
}

.ms-Icon--PinnedFill:before {
    content: "\E842";
}

.ms-Icon--PinnedSolid:before {
    content: "\F676";
}

.ms-Icon--PinSolid12:before {
    content: "\E352";
}

.ms-Icon--PinSolidOff12:before {
    content: "\E353";
}

.ms-Icon--PivotChart:before {
    content: "\F24C";
}

.ms-Icon--PlainText:before {
    content: "\F834";
}

.ms-Icon--PlannerLogo:before {
    content: "\EDD1";
}

.ms-Icon--PlanView:before {
    content: "\F360";
}

.ms-Icon--Play:before {
    content: "\E768";
}

.ms-Icon--PlaybackRate1x:before {
    content: "\EC57";
}

.ms-Icon--PlayerSettings:before {
    content: "\EF58";
}

.ms-Icon--PlayResume:before {
    content: "\F2C6";
}

.ms-Icon--PlayReverse:before {
    content: "\F3E5";
}

.ms-Icon--PlayReverseResume:before {
    content: "\F3E4";
}

.ms-Icon--PlaySolid:before {
    content: "\F5B0";
}

.ms-Icon--Plug:before {
    content: "\F300";
}

.ms-Icon--PlugConnected:before {
    content: "\F302";
}

.ms-Icon--PlugDisconnected:before {
    content: "\F303";
}

.ms-Icon--PlugSolid:before {
    content: "\F301";
}

.ms-Icon--POI:before {
    content: "\ECAF";
}

.ms-Icon--POISolid:before {
    content: "\F2D1";
}

.ms-Icon--PollResults:before {
    content: "\F8A0";
}

.ms-Icon--PopExpand:before {
    content: "\E49A";
}

.ms-Icon--PostUpdate:before {
    content: "\E8F3";
}

.ms-Icon--PowerApps:before {
    content: "\EDD2";
}

.ms-Icon--PowerApps2Logo:before {
    content: "\F092";
}

.ms-Icon--PowerAppsLogo:before {
    content: "\F091";
}

.ms-Icon--PowerAppsTemplate:before {
    content: "\E4AC";
}

.ms-Icon--PowerAutomateLogo:before {
    content: "\F4B1";
}

.ms-Icon--PowerBILogo:before {
    content: "\EA1E";
}

.ms-Icon--PowerBILogo16:before {
    content: "\F790";
}

.ms-Icon--PowerBILogoBackplate16:before {
    content: "\F791";
}

.ms-Icon--PowerButton:before {
    content: "\E7E8";
}

.ms-Icon--PowerPointDocument:before {
    content: "\EF72";
}

.ms-Icon--PowerPointLogo:before {
    content: "\F1EB";
}

.ms-Icon--PowerPointLogo16:before {
    content: "\F394";
}

.ms-Icon--PowerPointLogoInverse:before {
    content: "\EC2A";
}

.ms-Icon--PowerPointLogoInverse16:before {
    content: "\F393";
}

.ms-Icon--PowerShell:before {
    content: "\F1FD";
}

.ms-Icon--PowerShell2:before {
    content: "\F730";
}

.ms-Icon--PowerStandby:before {
    content: "\E55C";
}

.ms-Icon--PowerVirtualAgentsLogo:before {
    content: "\E484";
}

.ms-Icon--Precipitation:before {
    content: "\E9CF";
}

.ms-Icon--PresenceChickletVideo:before {
    content: "\E979";
}

.ms-Icon--Presentation:before {
    content: "\F6E4";
}

.ms-Icon--Presentation12:before {
    content: "\F6E5";
}

.ms-Icon--Preview:before {
    content: "\E8FF";
}

.ms-Icon--PreviewBelow:before {
    content: "\E5D5";
}

.ms-Icon--PreviewLink:before {
    content: "\E8A1";
}

.ms-Icon--PreviewSideBySide:before {
    content: "\E5D6";
}

.ms-Icon--Previous:before {
    content: "\E892";
}

.ms-Icon--PrimaryCalendar:before {
    content: "\F4AE";
}

.ms-Icon--Print:before {
    content: "\E749";
}

.ms-Icon--PrintfaxPrinterFile:before {
    content: "\E956";
}

.ms-Icon--Processing:before {
    content: "\E9F5";
}

.ms-Icon--ProcessingCancel:before {
    content: "\E403";
}

.ms-Icon--ProcessingPause:before {
    content: "\E405";
}

.ms-Icon--ProcessingRun:before {
    content: "\E404";
}

.ms-Icon--ProcessMetaTask:before {
    content: "\F290";
}

.ms-Icon--Product:before {
    content: "\ECDC";
}

.ms-Icon--ProductCatalog:before {
    content: "\EFE8";
}

.ms-Icon--ProductionFloorManagement:before {
    content: "\EE29";
}

.ms-Icon--ProductList:before {
    content: "\E31E";
}

.ms-Icon--ProductRelease:before {
    content: "\EE2E";
}

.ms-Icon--ProductVariant:before {
    content: "\EE30";
}

.ms-Icon--ProductWarning:before {
    content: "\E5C0";
}

.ms-Icon--ProfileSearch:before {
    content: "\EF35";
}

.ms-Icon--ProFootball:before {
    content: "\EB27";
}

.ms-Icon--ProgressLoopInner:before {
    content: "\ECDE";
}

.ms-Icon--ProgressLoopOuter:before {
    content: "\ECDF";
}

.ms-Icon--ProgressRing5:before {
    content: "\EDF6";
}

.ms-Icon--ProgressRingDots:before {
    content: "\F16A";
}

.ms-Icon--ProHockey:before {
    content: "\EB28";
}

.ms-Icon--ProjectCollection:before {
    content: "\F363";
}

.ms-Icon--ProjectDocument:before {
    content: "\F759";
}

.ms-Icon--ProjectLogo16:before {
    content: "\F480";
}

.ms-Icon--ProjectLogo32:before {
    content: "\F47E";
}

.ms-Icon--ProjectLogoInverse:before {
    content: "\EDD4";
}

.ms-Icon--PromotedDatabase:before {
    content: "\F77D";
}

.ms-Icon--Pronouns:before {
    content: "\E556";
}

.ms-Icon--ProtectedDocument:before {
    content: "\E8A6";
}

.ms-Icon--ProtectionCenterLogo32:before {
    content: "\F494";
}

.ms-Icon--ProtectRestrict:before {
    content: "\F22A";
}

.ms-Icon--ProvisioningPackage:before {
    content: "\E835";
}

.ms-Icon--PublicCalendar:before {
    content: "\EF6D";
}

.ms-Icon--PublicContactCard:before {
    content: "\EF6E";
}

.ms-Icon--PublicContactCardMirrored:before {
    content: "\F230";
}

.ms-Icon--PublicEmail:before {
    content: "\EF6F";
}

.ms-Icon--PublicFolder:before {
    content: "\EF70";
}

.ms-Icon--PublishContent:before {
    content: "\F0D4";
}

.ms-Icon--PublishCourse:before {
    content: "\F699";
}

.ms-Icon--PublisherLogo:before {
    content: "\F1ED";
}

.ms-Icon--PublisherLogo16:before {
    content: "\F3A0";
}

.ms-Icon--PublisherLogoInverse16:before {
    content: "\F39F";
}

.ms-Icon--Puzzle:before {
    content: "\EA86";
}

.ms-Icon--PY:before {
    content: "\F2F9";
}

.ms-Icon--PythonLanguage:before {
    content: "\F2F8";
}

.ms-Icon--QandA:before {
    content: "\F8A2";
}

.ms-Icon--QandAMirror:before {
    content: "\F8A3";
}

.ms-Icon--QRCode:before {
    content: "\ED14";
}

.ms-Icon--QuadColumn:before {
    content: "\F66F";
}

.ms-Icon--Quantity:before {
    content: "\E9F8";
}

.ms-Icon--QuarterCircle:before {
    content: "\F502";
}

.ms-Icon--QueryList:before {
    content: "\F2B8";
}

.ms-Icon--Questionnaire:before {
    content: "\EE19";
}

.ms-Icon--QuestionnaireMirrored:before {
    content: "\EE4B";
}

.ms-Icon--QuickNote:before {
    content: "\E70B";
}

.ms-Icon--QuickNoteSolid:before {
    content: "\F338";
}

.ms-Icon--Quotes:before {
    content: "\F067";
}

.ms-Icon--R:before {
    content: "\F4EB";
}

.ms-Icon--RadioBtnOff:before {
    content: "\ECCA";
}

.ms-Icon--RadioBtnOn:before {
    content: "\ECCB";
}

.ms-Icon--RadioBullet:before {
    content: "\E915";
}

.ms-Icon--Rain:before {
    content: "\E9C4";
}

.ms-Icon--RainShowersDay:before {
    content: "\E9C3";
}

.ms-Icon--RainShowersNight:before {
    content: "\EA0F";
}

.ms-Icon--RainSnow:before {
    content: "\E9C7";
}

.ms-Icon--Rate:before {
    content: "\EB07";
}

.ms-Icon--RawSource:before {
    content: "\F299";
}

.ms-Icon--Read:before {
    content: "\E8C3";
}

.ms-Icon--ReadingMode:before {
    content: "\E736";
}

.ms-Icon--ReadingModeSolid:before {
    content: "\F33D";
}

.ms-Icon--ReadOutLoud:before {
    content: "\F112";
}

.ms-Icon--RealEstate:before {
    content: "\E758";
}

.ms-Icon--ReceiptCheck:before {
    content: "\EF5B";
}

.ms-Icon--ReceiptForward:before {
    content: "\EF59";
}

.ms-Icon--ReceiptProcessing:before {
    content: "\E496";
}

.ms-Icon--ReceiptReply:before {
    content: "\EF5A";
}

.ms-Icon--ReceiptTentative:before {
    content: "\F41A";
}

.ms-Icon--ReceiptTentativeMirrored:before {
    content: "\F41B";
}

.ms-Icon--ReceiptUndelivered:before {
    content: "\F419";
}

.ms-Icon--Recent:before {
    content: "\E823";
}

.ms-Icon--Record2:before {
    content: "\EA3F";
}

.ms-Icon--RecruitmentManagement:before {
    content: "\EE12";
}

.ms-Icon--RectangleShape:before {
    content: "\F1A9";
}

.ms-Icon--RectangleShapeSolid:before {
    content: "\F640";
}

.ms-Icon--RectangularClipping:before {
    content: "\F407";
}

.ms-Icon--RecurringEvent:before {
    content: "\EF5D";
}

.ms-Icon--RecurringTask:before {
    content: "\EDB2";
}

.ms-Icon--RecycleBin:before {
    content: "\EF87";
}

.ms-Icon--Redeploy:before {
    content: "\F29E";
}

.ms-Icon--RedEye:before {
    content: "\E7B3";
}

.ms-Icon--RedEye12:before {
    content: "\E3AD";
}

.ms-Icon--Redo:before {
    content: "\E7A6";
}

.ms-Icon--Refresh:before {
    content: "\E72C";
}

.ms-Icon--RegistryEditor:before {
    content: "\F1FF";
}

.ms-Icon--Relationship:before {
    content: "\F003";
}

.ms-Icon--ReleaseDefinition:before {
    content: "\F6EA";
}

.ms-Icon--ReleaseGate:before {
    content: "\F7BE";
}

.ms-Icon--ReleaseGateCheck:before {
    content: "\F7BF";
}

.ms-Icon--ReleaseGateError:before {
    content: "\F7C0";
}

.ms-Icon--ReminderGroup:before {
    content: "\EBF8";
}

.ms-Icon--ReminderPerson:before {
    content: "\EBF7";
}

.ms-Icon--ReminderTime:before {
    content: "\EBF9";
}

.ms-Icon--Remote:before {
    content: "\E8AF";
}

.ms-Icon--Remove:before {
    content: "\E738";
}

.ms-Icon--RemoveContent:before {
    content: "\ECC7";
}

.ms-Icon--RemoveEvent:before {
    content: "\ED8A";
}

.ms-Icon--RemoveFilter:before {
    content: "\EB08";
}

.ms-Icon--RemoveFromShoppingList:before {
    content: "\F7D5";
}

.ms-Icon--RemoveFromTrash:before {
    content: "\F82B";
}

.ms-Icon--RemoveLink:before {
    content: "\ED90";
}

.ms-Icon--RemoveLinkChain:before {
    content: "\F79A";
}

.ms-Icon--RemoveLinkX:before {
    content: "\F79B";
}

.ms-Icon--RemoveOccurrence:before {
    content: "\ED9B";
}

.ms-Icon--Rename:before {
    content: "\E8AC";
}

.ms-Icon--RenewalCurrent:before {
    content: "\F545";
}

.ms-Icon--RenewalFuture:before {
    content: "\F546";
}

.ms-Icon--ReopenPages:before {
    content: "\ED50";
}

.ms-Icon--Repair:before {
    content: "\E90F";
}

.ms-Icon--RepeatAll:before {
    content: "\E8EE";
}

.ms-Icon--RepeatHeaderRows:before {
    content: "\E3EB";
}

.ms-Icon--RepeatOne:before {
    content: "\E8ED";
}

.ms-Icon--Reply:before {
    content: "\E97A";
}

.ms-Icon--ReplyAll:before {
    content: "\EE0A";
}

.ms-Icon--ReplyAllAlt:before {
    content: "\EF5F";
}

.ms-Icon--ReplyAllMirrored:before {
    content: "\EE36";
}

.ms-Icon--ReplyAlt:before {
    content: "\EF5E";
}

.ms-Icon--ReplyMirrored:before {
    content: "\EE35";
}

.ms-Icon--Repo:before {
    content: "\F2CB";
}

.ms-Icon--ReportAdd:before {
    content: "\F52C";
}

.ms-Icon--ReportDocument:before {
    content: "\E9F9";
}

.ms-Icon--ReportHacked:before {
    content: "\E730";
}

.ms-Icon--ReportLibrary:before {
    content: "\EEBB";
}

.ms-Icon--ReportLibraryMirrored:before {
    content: "\EEBC";
}

.ms-Icon--ReportLock:before {
    content: "\F875";
}

.ms-Icon--ReportWarning:before {
    content: "\F569";
}

.ms-Icon--RepoSolid:before {
    content: "\F2CC";
}

.ms-Icon--Rerun:before {
    content: "\F8A1";
}

.ms-Icon--ReservationOrders:before {
    content: "\F845";
}

.ms-Icon--Reset:before {
    content: "\E423";
}

.ms-Icon--ResetDevice:before {
    content: "\ED10";
}

.ms-Icon--ResponsesMenu:before {
    content: "\F768";
}

.ms-Icon--ReturnKey:before {
    content: "\E751";
}

.ms-Icon--ReturnToSession:before {
    content: "\ED24";
}

.ms-Icon--ReviewRequestMirroredSolid:before {
    content: "\F357";
}

.ms-Icon--ReviewRequestSolid:before {
    content: "\F356";
}

.ms-Icon--ReviewResponseSolid:before {
    content: "\F358";
}

.ms-Icon--ReviewSolid:before {
    content: "\F355";
}

.ms-Icon--RevToggleKey:before {
    content: "\E845";
}

.ms-Icon--RewardsLogo:before {
    content: "\ED4E";
}

.ms-Icon--RewardsLogoArt64:before {
    content: "\EEF2";
}

.ms-Icon--RewardsLogoSolid:before {
    content: "\ED4F";
}

.ms-Icon--Rewind:before {
    content: "\EB9E";
}

.ms-Icon--RewindEightX:before {
    content: "\E449";
}

.ms-Icon--RewindFourX:before {
    content: "\E448";
}

.ms-Icon--RewindOneFiveX:before {
    content: "\E446";
}

.ms-Icon--RewindOneX:before {
    content: "\E445";
}

.ms-Icon--RewindPointFiveX:before {
    content: "\E444";
}

.ms-Icon--RewindTwoX:before {
    content: "\E447";
}

.ms-Icon--Ribbon:before {
    content: "\E9D1";
}

.ms-Icon--RibbonSolid:before {
    content: "\F345";
}

.ms-Icon--RightDoubleQuote:before {
    content: "\E9B1";
}

.ms-Icon--RightTriangle:before {
    content: "\F500";
}

.ms-Icon--Ringer:before {
    content: "\EA8F";
}

.ms-Icon--RingerActive:before {
    content: "\E498";
}

.ms-Icon--RingerOff:before {
    content: "\F2C5";
}

.ms-Icon--RingerRemove:before {
    content: "\F279";
}

.ms-Icon--RingerSolid:before {
    content: "\EF3A";
}

.ms-Icon--Robot:before {
    content: "\E99A";
}

.ms-Icon--Rocket:before {
    content: "\F3B3";
}

.ms-Icon--Room:before {
    content: "\ED9F";
}

.ms-Icon--Rotate:before {
    content: "\E7AD";
}

.ms-Icon--Rotate90Clockwise:before {
    content: "\F80D";
}

.ms-Icon--Rotate90CounterClockwise:before {
    content: "\F80E";
}

.ms-Icon--RowsChild:before {
    content: "\F29C";
}

.ms-Icon--RowsGroup:before {
    content: "\F29B";
}

.ms-Icon--Rugby:before {
    content: "\EB2D";
}

.ms-Icon--Running:before {
    content: "\EADA";
}

.ms-Icon--Sad:before {
    content: "\E757";
}

.ms-Icon--SadSolid:before {
    content: "\F33E";
}

.ms-Icon--SamsungGallery:before {
    content: "\E4E2";
}

.ms-Icon--Save:before {
    content: "\E74E";
}

.ms-Icon--SaveAll:before {
    content: "\F203";
}

.ms-Icon--SaveAndClose:before {
    content: "\F038";
}

.ms-Icon--SaveAs:before {
    content: "\E792";
}

.ms-Icon--SavedOffline:before {
    content: "\E546";
}

.ms-Icon--SaveTemplate:before {
    content: "\F6EC";
}

.ms-Icon--SaveToMobile:before {
    content: "\F7E0";
}

.ms-Icon--Savings:before {
    content: "\EB0B";
}

.ms-Icon--ScaleUp:before {
    content: "\ED09";
}

.ms-Icon--ScaleVolume:before {
    content: "\F18C";
}

.ms-Icon--ScatterChart:before {
    content: "\EFEB";
}

.ms-Icon--ScheduleEventAction:before {
    content: "\F1EF";
}

.ms-Icon--SchoolDataSyncLogo:before {
    content: "\E34C";
}

.ms-Icon--ScopeTemplate:before {
    content: "\F2B0";
}

.ms-Icon--Screen:before {
    content: "\EF39";
}

.ms-Icon--ScreenCast:before {
    content: "\F7E2";
}

.ms-Icon--ScreenPreviewOn:before {
    content: "\F11E";
}

.ms-Icon--ScreenTime:before {
    content: "\F182";
}

.ms-Icon--Script:before {
    content: "\F03A";
}

.ms-Icon--ScrollUpDown:before {
    content: "\EC8F";
}

.ms-Icon--SDCard:before {
    content: "\E7F1";
}

.ms-Icon--Search:before {
    content: "\E721";
}

.ms-Icon--SearchAndApps:before {
    content: "\E773";
}

.ms-Icon--SearchBookmark:before {
    content: "\F5B8";
}

.ms-Icon--SearchCalendar:before {
    content: "\F4AF";
}

.ms-Icon--SearchData:before {
    content: "\F3F1";
}

.ms-Icon--SearchIssue:before {
    content: "\F09A";
}

.ms-Icon--SearchIssueMirrored:before {
    content: "\F09B";
}

.ms-Icon--SearchNearby:before {
    content: "\E820";
}

.ms-Icon--SecondaryNav:before {
    content: "\F814";
}

.ms-Icon--Section:before {
    content: "\EC0C";
}

.ms-Icon--Sections:before {
    content: "\EF76";
}

.ms-Icon--SecurityCamera:before {
    content: "\EB35";
}

.ms-Icon--SecurityGroup:before {
    content: "\ED85";
}

.ms-Icon--SecurityTest:before {
    content: "\E48F";
}

.ms-Icon--SeeDo:before {
    content: "\E808";
}

.ms-Icon--SelectAll:before {
    content: "\E8B3";
}

.ms-Icon--Sell:before {
    content: "\EB0C";
}

.ms-Icon--SemiboldWeight:before {
    content: "\F4F0";
}

.ms-Icon--Send:before {
    content: "\E724";
}

.ms-Icon--SendMirrored:before {
    content: "\EA63";
}

.ms-Icon--SentimentAnalysis:before {
    content: "\E393";
}

.ms-Icon--Separator:before {
    content: "\F35E";
}

.ms-Icon--Server:before {
    content: "\F201";
}

.ms-Icon--ServerEnviroment:before {
    content: "\F29F";
}

.ms-Icon--ServerProcesses:before {
    content: "\F1FE";
}

.ms-Icon--ServiceOff:before {
    content: "\E3FD";
}

.ms-Icon--SetAction:before {
    content: "\F071";
}

.ms-Icon--Settings:before {
    content: "\E713";
}

.ms-Icon--SettingsAdd:before {
    content: "\E35A";
}

.ms-Icon--SettingsSecure:before {
    content: "\E4D2";
}

.ms-Icon--SettingsSync:before {
    content: "\E359";
}

.ms-Icon--ShakeDevice:before {
    content: "\F80A";
}

.ms-Icon--Shapes:before {
    content: "\EC7C";
}

.ms-Icon--ShapeSolid:before {
    content: "\E422";
}

.ms-Icon--Share:before {
    content: "\E72D";
}

.ms-Icon--SharedDatabase:before {
    content: "\E3D9";
}

.ms-Icon--ShareiOS:before {
    content: "\EF79";
}

.ms-Icon--Sharepoint2013LogoInverse:before {
    content: "\E480";
}

.ms-Icon--SharepointAppIcon16:before {
    content: "\E365";
}

.ms-Icon--SharepointLogo:before {
    content: "\F27E";
}

.ms-Icon--SharepointLogoInverse:before {
    content: "\ED18";
}

.ms-Icon--Shield:before {
    content: "\EA18";
}

.ms-Icon--ShieldAlert:before {
    content: "\F7D7";
}

.ms-Icon--ShieldSolid:before {
    content: "\F340";
}

.ms-Icon--Shirt:before {
    content: "\ED00";
}

.ms-Icon--Shop:before {
    content: "\E719";
}

.ms-Icon--ShoppingCart:before {
    content: "\E7BF";
}

.ms-Icon--ShoppingCartSolid:before {
    content: "\F342";
}

.ms-Icon--ShopServer:before {
    content: "\F2B6";
}

.ms-Icon--Showers:before {
    content: "\E46C";
}

.ms-Icon--ShowGrid:before {
    content: "\F7DE";
}

.ms-Icon--ShowResults:before {
    content: "\E8BC";
}

.ms-Icon--ShowResultsMirrored:before {
    content: "\EA65";
}

.ms-Icon--ShowTimeAs:before {
    content: "\F787";
}

.ms-Icon--ShowVisualFilter:before {
    content: "\F4DE";
}

.ms-Icon--SidePanel:before {
    content: "\EF52";
}

.ms-Icon--SidePanelMirrored:before {
    content: "\F221";
}

.ms-Icon--Signin:before {
    content: "\F286";
}

.ms-Icon--SignOut:before {
    content: "\F3B1";
}

.ms-Icon--SimplifiedView:before {
    content: "\E5C1";
}

.ms-Icon--SingleBookmark:before {
    content: "\EDFF";
}

.ms-Icon--SingleBookmarkSolid:before {
    content: "\EE00";
}

.ms-Icon--SingleColumn:before {
    content: "\F1D3";
}

.ms-Icon--SingleColumnEdit:before {
    content: "\F321";
}

.ms-Icon--SIPMove:before {
    content: "\E759";
}

.ms-Icon--SiteScan:before {
    content: "\EBEC";
}

.ms-Icon--SizeLegacy:before {
    content: "\E2B2";
}

.ms-Icon--SkipBack10:before {
    content: "\ED3C";
}

.ms-Icon--SkipForward30:before {
    content: "\ED3D";
}

.ms-Icon--SkiResorts:before {
    content: "\EB45";
}

.ms-Icon--SkypeArrow:before {
    content: "\F748";
}

.ms-Icon--SkypeCheck:before {
    content: "\EF80";
}

.ms-Icon--SkypeCircleArrow:before {
    content: "\F747";
}

.ms-Icon--SkypeCircleCheck:before {
    content: "\EF7D";
}

.ms-Icon--SkypeCircleClock:before {
    content: "\EF7E";
}

.ms-Icon--SkypeCircleMinus:before {
    content: "\EF7F";
}

.ms-Icon--SkypeCircleSlash:before {
    content: "\F825";
}

.ms-Icon--SkypeClock:before {
    content: "\EF81";
}

.ms-Icon--SkypeForBusinessLogo:before {
    content: "\F0FC";
}

.ms-Icon--SkypeForBusinessLogo16:before {
    content: "\F40F";
}

.ms-Icon--SkypeForBusinessLogoFill:before {
    content: "\F27D";
}

.ms-Icon--SkypeForBusinessLogoFill16:before {
    content: "\F410";
}

.ms-Icon--SkypeLogo:before {
    content: "\EB6F";
}

.ms-Icon--SkypeLogo16:before {
    content: "\F40E";
}

.ms-Icon--SkypeMessage:before {
    content: "\EF83";
}

.ms-Icon--SkypeMinus:before {
    content: "\EF82";
}

.ms-Icon--SkypeSlash:before {
    content: "\F826";
}

.ms-Icon--Sleet:before {
    content: "\E474";
}

.ms-Icon--Slider:before {
    content: "\F527";
}

.ms-Icon--SliderHandleSize:before {
    content: "\F528";
}

.ms-Icon--SliderThumb:before {
    content: "\EC13";
}

.ms-Icon--Slideshow:before {
    content: "\E786";
}

.ms-Icon--SmartGlassRemote:before {
    content: "\F80B";
}

.ms-Icon--SnapToGrid:before {
    content: "\F7E4";
}

.ms-Icon--Snooze:before {
    content: "\F4BD";
}

.ms-Icon--Snow:before {
    content: "\E9C8";
}

.ms-Icon--Snowflake:before {
    content: "\EB46";
}

.ms-Icon--SnowShowerDay:before {
    content: "\E9FD";
}

.ms-Icon--SnowShowerNight:before {
    content: "\EA11";
}

.ms-Icon--Soccer:before {
    content: "\EB21";
}

.ms-Icon--SocialListeningLogo:before {
    content: "\ED7C";
}

.ms-Icon--Sort:before {
    content: "\E8CB";
}

.ms-Icon--SortDown:before {
    content: "\EE69";
}

.ms-Icon--SortLines:before {
    content: "\E9D0";
}

.ms-Icon--SortLinesAscending:before {
    content: "\E43A";
}

.ms-Icon--SortUp:before {
    content: "\EE68";
}

.ms-Icon--Source:before {
    content: "\EB1B";
}

.ms-Icon--Spacer:before {
    content: "\F40D";
}

.ms-Icon--Speakers:before {
    content: "\E7F5";
}

.ms-Icon--SpecialEvent:before {
    content: "\F536";
}

.ms-Icon--Speech:before {
    content: "\EFA9";
}

.ms-Icon--SpeechOff:before {
    content: "\F8BA";
}

.ms-Icon--SpeedHigh:before {
    content: "\EC4A";
}

.ms-Icon--Spelling:before {
    content: "\F87B";
}

.ms-Icon--Split:before {
    content: "\EDBC";
}

.ms-Icon--SplitObject:before {
    content: "\F547";
}

.ms-Icon--Sprint:before {
    content: "\F3B0";
}

.ms-Icon--SQLAnalyticsPool:before {
    content: "\E434";
}

.ms-Icon--Squalls:before {
    content: "\E9CC";
}

.ms-Icon--SquareShape:before {
    content: "\F1A6";
}

.ms-Icon--SquareShapeSolid:before {
    content: "\F63D";
}

.ms-Icon--Stack:before {
    content: "\F26F";
}

.ms-Icon--StackColumnChart:before {
    content: "\E9FC";
}

.ms-Icon--StackedBarChart:before {
    content: "\F24D";
}

.ms-Icon--StackedColumnChart2:before {
    content: "\F666";
}

.ms-Icon--StackedColumnChart2Fill:before {
    content: "\F831";
}

.ms-Icon--StackedLineChart:before {
    content: "\F24E";
}

.ms-Icon--StackIndicator:before {
    content: "\E7FF";
}

.ms-Icon--StaffNotebookLogo16:before {
    content: "\F48E";
}

.ms-Icon--StaffNotebookLogo32:before {
    content: "\F48C";
}

.ms-Icon--StaffNotebookLogoFill16:before {
    content: "\F48F";
}

.ms-Icon--StaffNotebookLogoFill32:before {
    content: "\F48D";
}

.ms-Icon--StaffNotebookLogoInverted16:before {
    content: "\F491";
}

.ms-Icon--StaffNotebookLogoInverted32:before {
    content: "\F490";
}

.ms-Icon--Starburst:before {
    content: "\EF78";
}

.ms-Icon--StarburstSolid:before {
    content: "\F33C";
}

.ms-Icon--StatusCircleBlock:before {
    content: "\F140";
}

.ms-Icon--StatusCircleBlock2:before {
    content: "\F141";
}

.ms-Icon--StatusCircleCheckmark:before {
    content: "\F13E";
}

.ms-Icon--StatusCircleErrorX:before {
    content: "\F13D";
}

.ms-Icon--StatusCircleExclamation:before {
    content: "\F13C";
}

.ms-Icon--StatusCircleInfo:before {
    content: "\F13F";
}

.ms-Icon--StatusCircleInner:before {
    content: "\F137";
}

.ms-Icon--StatusCircleOuter:before {
    content: "\F136";
}

.ms-Icon--StatusCircleQuestionMark:before {
    content: "\F142";
}

.ms-Icon--StatusCircleRing:before {
    content: "\F138";
}

.ms-Icon--StatusCircleSync:before {
    content: "\F143";
}

.ms-Icon--StatusErrorFull:before {
    content: "\EB90";
}

.ms-Icon--StatusTriangle:before {
    content: "\EA82";
}

.ms-Icon--StatusTriangleExclamation:before {
    content: "\F13B";
}

.ms-Icon--StatusTriangleInner:before {
    content: "\F13A";
}

.ms-Icon--StatusTriangleOuter:before {
    content: "\F139";
}

.ms-Icon--Step:before {
    content: "\F241";
}

.ms-Icon--StepInsert:before {
    content: "\F242";
}

.ms-Icon--StepShared:before {
    content: "\F243";
}

.ms-Icon--StepSharedAdd:before {
    content: "\F244";
}

.ms-Icon--StepSharedInsert:before {
    content: "\F245";
}

.ms-Icon--StickyNotesOutlineAppIcon:before {
    content: "\E36A";
}

.ms-Icon--StickyNotesSolidAppIcon:before {
    content: "\E36B";
}

.ms-Icon--StockDown:before {
    content: "\EB0F";
}

.ms-Icon--StockUp:before {
    content: "\EB11";
}

.ms-Icon--Stop:before {
    content: "\E71A";
}

.ms-Icon--StopSolid:before {
    content: "\EE95";
}

.ms-Icon--Stopwatch:before {
    content: "\E916";
}

.ms-Icon--StorageAcount:before {
    content: "\E435";
}

.ms-Icon--StorageOptical:before {
    content: "\E958";
}

.ms-Icon--StoreLogo16:before {
    content: "\EA96";
}

.ms-Icon--StoreLogoMed20:before {
    content: "\EA04";
}

.ms-Icon--Storyboard:before {
    content: "\F308";
}

.ms-Icon--StreamDiscover:before {
    content: "\F7D9";
}

.ms-Icon--Streaming:before {
    content: "\E93E";
}

.ms-Icon--StreamingOff:before {
    content: "\F2BB";
}

.ms-Icon--StreamLogo:before {
    content: "\F329";
}

.ms-Icon--Street:before {
    content: "\E913";
}

.ms-Icon--StreetsideSplitMinimize:before {
    content: "\E802";
}

.ms-Icon--Strikethrough:before {
    content: "\EDE0";
}

.ms-Icon--StrikethroughKorean:before {
    content: "\E5D4";
}

.ms-Icon--Subscribe:before {
    content: "\EDA1";
}

.ms-Icon--Subscript:before {
    content: "\EDDF";
}

.ms-Icon--SubstitutionsIn:before {
    content: "\EB31";
}

.ms-Icon--Suitcase:before {
    content: "\EDD3";
}

.ms-Icon--SunAdd:before {
    content: "\EF69";
}

.ms-Icon--Sunny:before {
    content: "\E9BD";
}

.ms-Icon--SunQuestionMark:before {
    content: "\EF6A";
}

.ms-Icon--Superscript:before {
    content: "\EDDE";
}

.ms-Icon--SurveyQuestionResponse:before {
    content: "\E4F2";
}

.ms-Icon--SurveyQuestions:before {
    content: "\F01B";
}

.ms-Icon--SwayLogo16:before {
    content: "\F484";
}

.ms-Icon--SwayLogo32:before {
    content: "\F482";
}

.ms-Icon--SwayLogoFill16:before {
    content: "\F485";
}

.ms-Icon--SwayLogoFill32:before {
    content: "\F483";
}

.ms-Icon--SwayLogoInverse:before {
    content: "\ED29";
}

.ms-Icon--Switch:before {
    content: "\E8AB";
}

.ms-Icon--SwitcherStartEnd:before {
    content: "\E810";
}

.ms-Icon--SwitchUser:before {
    content: "\E748";
}

.ms-Icon--Sync:before {
    content: "\E895";
}

.ms-Icon--SyncError:before {
    content: "\EA6A";
}

.ms-Icon--SyncFolder:before {
    content: "\E8F7";
}

.ms-Icon--SyncOccurence:before {
    content: "\F4A3";
}

.ms-Icon--SyncStatus:before {
    content: "\F751";
}

.ms-Icon--SyncStatusSolid:before {
    content: "\F752";
}

.ms-Icon--SyncToPC:before {
    content: "\EE6E";
}

.ms-Icon--System:before {
    content: "\E770";
}

.ms-Icon--Tab:before {
    content: "\E7E9";
}

.ms-Icon--TabCenter:before {
    content: "\F100";
}

.ms-Icon--Table:before {
    content: "\ED86";
}

.ms-Icon--TableBrandedColumn:before {
    content: "\E3F1";
}

.ms-Icon--TableBrandedRow:before {
    content: "\E3EE";
}

.ms-Icon--TableColumn:before {
    content: "\E4BD";
}

.ms-Icon--TableComputed:before {
    content: "\F8F5";
}

.ms-Icon--TableFirstColumn:before {
    content: "\E3EF";
}

.ms-Icon--TableGroup:before {
    content: "\F6D9";
}

.ms-Icon--TableHeaderRow:before {
    content: "\E3EC";
}

.ms-Icon--TableLastColumn:before {
    content: "\E3F0";
}

.ms-Icon--TableLink:before {
    content: "\F77A";
}

.ms-Icon--Tablet:before {
    content: "\E70A";
}

.ms-Icon--TabletMode:before {
    content: "\EBFC";
}

.ms-Icon--TableTotalRow:before {
    content: "\E3ED";
}

.ms-Icon--TabletSelected:before {
    content: "\EC74";
}

.ms-Icon--TabOneColumn:before {
    content: "\F849";
}

.ms-Icon--TabThreeColumn:before {
    content: "\F84B";
}

.ms-Icon--TabTwoColumn:before {
    content: "\F84A";
}

.ms-Icon--Tag:before {
    content: "\E8EC";
}

.ms-Icon--TagGroup:before {
    content: "\E3F6";
}

.ms-Icon--TagSolid:before {
    content: "\F70E";
}

.ms-Icon--TagUnknown:before {
    content: "\F6DF";
}

.ms-Icon--TagUnknown12:before {
    content: "\F6E1";
}

.ms-Icon--TagUnknown12Mirror:before {
    content: "\F6E2";
}

.ms-Icon--TagUnknownMirror:before {
    content: "\F6E0";
}

.ms-Icon--TaskAdd:before {
    content: "\E4FD";
}

.ms-Icon--Taskboard:before {
    content: "\F1C2";
}

.ms-Icon--TaskGroup:before {
    content: "\F2AE";
}

.ms-Icon--TaskGroupMirrored:before {
    content: "\F2AF";
}

.ms-Icon--TaskList:before {
    content: "\E3B6";
}

.ms-Icon--TaskLogo:before {
    content: "\F493";
}

.ms-Icon--TaskManager:before {
    content: "\EDB7";
}

.ms-Icon--TaskManagerMirrored:before {
    content: "\EDB8";
}

.ms-Icon--TaskSolid:before {
    content: "\F333";
}

.ms-Icon--Taxi:before {
    content: "\F4A1";
}

.ms-Icon--TeamFavorite:before {
    content: "\F2AD";
}

.ms-Icon--TeamsLogo:before {
    content: "\F27B";
}

.ms-Icon--TeamsLogo16:before {
    content: "\F40A";
}

.ms-Icon--TeamsLogoInverse:before {
    content: "\F27A";
}

.ms-Icon--Teamwork:before {
    content: "\EA12";
}

.ms-Icon--Teeth:before {
    content: "\F4A0";
}

.ms-Icon--Telemarketer:before {
    content: "\E7B9";
}

.ms-Icon--TemporaryAccessPass:before {
    content: "\E4AD";
}

.ms-Icon--TemporaryUser:before {
    content: "\EE58";
}

.ms-Icon--Tennis:before {
    content: "\EB33";
}

.ms-Icon--TestAdd:before {
    content: "\E4DC";
}

.ms-Icon--TestAutoSolid:before {
    content: "\F3A8";
}

.ms-Icon--TestBeaker:before {
    content: "\F3A5";
}

.ms-Icon--TestBeakerSolid:before {
    content: "\F3A6";
}

.ms-Icon--TestCase:before {
    content: "\F3AF";
}

.ms-Icon--TestExploreSolid:before {
    content: "\F3A7";
}

.ms-Icon--TestImpactSolid:before {
    content: "\F3AA";
}

.ms-Icon--TestParameter:before {
    content: "\F3AD";
}

.ms-Icon--TestPlan:before {
    content: "\F3AB";
}

.ms-Icon--TestRemove:before {
    content: "\E4E1";
}

.ms-Icon--TestStep:before {
    content: "\F3AC";
}

.ms-Icon--TestSuite:before {
    content: "\F3AE";
}

.ms-Icon--TestUserSolid:before {
    content: "\F3A9";
}

.ms-Icon--TextAlignBottom:before {
    content: "\E3E2";
}

.ms-Icon--TextAlignMiddle:before {
    content: "\E3E1";
}

.ms-Icon--TextAlignTop:before {
    content: "\E3E0";
}

.ms-Icon--TextBox:before {
    content: "\EDC2";
}

.ms-Icon--TextCallout:before {
    content: "\F2A2";
}

.ms-Icon--TextDocument:before {
    content: "\F029";
}

.ms-Icon--TextDocumentEdit:before {
    content: "\E43B";
}

.ms-Icon--TextDocumentSettings:before {
    content: "\E4AA";
}

.ms-Icon--TextDocumentShared:before {
    content: "\F02B";
}

.ms-Icon--TextField:before {
    content: "\EDC3";
}

.ms-Icon--TextOverflow:before {
    content: "\F51F";
}

.ms-Icon--TextParagraphOption:before {
    content: "\E3E3";
}

.ms-Icon--TextRecognition:before {
    content: "\E394";
}

.ms-Icon--TextRotate270Degrees:before {
    content: "\E3E7";
}

.ms-Icon--TextRotate90Degrees:before {
    content: "\E3E6";
}

.ms-Icon--TextRotateHorizontal:before {
    content: "\E3E5";
}

.ms-Icon--TextRotation:before {
    content: "\E3E4";
}

.ms-Icon--TFVCLogo:before {
    content: "\F44D";
}

.ms-Icon--ThisPC:before {
    content: "\EC4E";
}

.ms-Icon--ThreeQuarterCircle:before {
    content: "\F503";
}

.ms-Icon--ThumbnailView:before {
    content: "\E7B6";
}

.ms-Icon--ThumbnailViewMirrored:before {
    content: "\EA67";
}

.ms-Icon--Thunderstorms:before {
    content: "\E9C6";
}

.ms-Icon--Ticket:before {
    content: "\EB54";
}

.ms-Icon--Tiles:before {
    content: "\ECA5";
}

.ms-Icon--Tiles2:before {
    content: "\EF7C";
}

.ms-Icon--TimeEntry:before {
    content: "\EF95";
}

.ms-Icon--Timeline:before {
    content: "\ED9C";
}

.ms-Icon--TimelineDelivery:before {
    content: "\F2AB";
}

.ms-Icon--TimelineMatrixView:before {
    content: "\F361";
}

.ms-Icon--TimelineProgress:before {
    content: "\F2AA";
}

.ms-Icon--TimePicker:before {
    content: "\E367";
}

.ms-Icon--Timer:before {
    content: "\E91E";
}

.ms-Icon--TimeSheet:before {
    content: "\EA05";
}

.ms-Icon--ToDoLogoBottom:before {
    content: "\F4B3";
}

.ms-Icon--ToDoLogoInverse:before {
    content: "\F4BC";
}

.ms-Icon--ToDoLogoOutline:before {
    content: "\F75B";
}

.ms-Icon--ToDoLogoTop:before {
    content: "\F4B4";
}

.ms-Icon--ToggleBorder:before {
    content: "\EC12";
}

.ms-Icon--ToggleFilled:before {
    content: "\EC11";
}

.ms-Icon--ToggleLeft:before {
    content: "\F19E";
}

.ms-Icon--ToggleRight:before {
    content: "\F19F";
}

.ms-Icon--ToggleThumb:before {
    content: "\EC14";
}

.ms-Icon--Toll:before {
    content: "\F160";
}

.ms-Icon--Toolbox:before {
    content: "\ECED";
}

.ms-Icon--Total:before {
    content: "\E9DF";
}

.ms-Icon--Touch:before {
    content: "\E815";
}

.ms-Icon--TouchPointer:before {
    content: "\E7C9";
}

.ms-Icon--Trackers:before {
    content: "\EADF";
}

.ms-Icon--TrackersMirrored:before {
    content: "\EE92";
}

.ms-Icon--Train:before {
    content: "\E7C0";
}

.ms-Icon--TrainSolid:before {
    content: "\EB4D";
}

.ms-Icon--TransferCall:before {
    content: "\ED95";
}

.ms-Icon--Transition:before {
    content: "\F3BC";
}

.ms-Icon--TransitionEffect:before {
    content: "\F5B4";
}

.ms-Icon--TransitionPop:before {
    content: "\F5B2";
}

.ms-Icon--TransitionPush:before {
    content: "\F5B3";
}

.ms-Icon--Translate:before {
    content: "\E7B2";
}

.ms-Icon--Trending12:before {
    content: "\F62D";
}

.ms-Icon--TriangleDown12:before {
    content: "\EED1";
}

.ms-Icon--TriangleLeft12:before {
    content: "\EED2";
}

.ms-Icon--TriangleRight12:before {
    content: "\EED3";
}

.ms-Icon--TriangleShape:before {
    content: "\F1A7";
}

.ms-Icon--TriangleShapeSolid:before {
    content: "\F63E";
}

.ms-Icon--TriangleSolid:before {
    content: "\EA08";
}

.ms-Icon--TriangleSolidDown12:before {
    content: "\EECD";
}

.ms-Icon--TriangleSolidLeft12:before {
    content: "\EECE";
}

.ms-Icon--TriangleSolidRight12:before {
    content: "\EECF";
}

.ms-Icon--TriangleSolidUp12:before {
    content: "\EECC";
}

.ms-Icon--TriangleUp12:before {
    content: "\EED0";
}

.ms-Icon--TriggerApproval:before {
    content: "\F3B2";
}

.ms-Icon--TriggerAuto:before {
    content: "\F24A";
}

.ms-Icon--TriggerUser:before {
    content: "\F24B";
}

.ms-Icon--Trim:before {
    content: "\E78A";
}

.ms-Icon--TrimEnd:before {
    content: "\F8BC";
}

.ms-Icon--TrimStart:before {
    content: "\F8BB";
}

.ms-Icon--TripleColumn:before {
    content: "\F1D5";
}

.ms-Icon--TripleColumnEdit:before {
    content: "\F323";
}

.ms-Icon--TripleColumnWide:before {
    content: "\F66E";
}

.ms-Icon--Trophy:before {
    content: "\ED3F";
}

.ms-Icon--Trophy2:before {
    content: "\F1AE";
}

.ms-Icon--Trophy2Solid:before {
    content: "\F337";
}

.ms-Icon--TurnRight:before {
    content: "\E7DB";
}

.ms-Icon--TVMonitor:before {
    content: "\E7F4";
}

.ms-Icon--TVMonitorSelected:before {
    content: "\EC77";
}

.ms-Icon--TypeScriptLanguage:before {
    content: "\F2F7";
}

.ms-Icon--Umbrella:before {
    content: "\EC04";
}

.ms-Icon--UnavailableOffline:before {
    content: "\E545";
}

.ms-Icon--Underline:before {
    content: "\E8DC";
}

.ms-Icon--UnderlineA:before {
    content: "\E5CC";
}

.ms-Icon--UnderlineKorean:before {
    content: "\E5BB";
}

.ms-Icon--UnderlineP:before {
    content: "\E5CD";
}

.ms-Icon--UnderlineRussian:before {
    content: "\E5B8";
}

.ms-Icon--UnderlineS:before {
    content: "\E5B4";
}

.ms-Icon--UnderlineSerbian:before {
    content: "\E5CB";
}

.ms-Icon--Undo:before {
    content: "\E7A7";
}

.ms-Icon--Uneditable:before {
    content: "\ED1D";
}

.ms-Icon--Uneditable2:before {
    content: "\F876";
}

.ms-Icon--Uneditable2Mirrored:before {
    content: "\F877";
}

.ms-Icon--UneditableMirrored:before {
    content: "\F4B9";
}

.ms-Icon--UneditableSolid12:before {
    content: "\F4B7";
}

.ms-Icon--UneditableSolidMirrored12:before {
    content: "\F4B8";
}

.ms-Icon--Unfavorite:before {
    content: "\E8D9";
}

.ms-Icon--UngroupObject:before {
    content: "\F4F2";
}

.ms-Icon--Unknown:before {
    content: "\E9CE";
}

.ms-Icon--UnknownCall:before {
    content: "\ED97";
}

.ms-Icon--UnknownMirrored:before {
    content: "\F22E";
}

.ms-Icon--UnknownMirroredSolid:before {
    content: "\F2E2";
}

.ms-Icon--UnknownSolid:before {
    content: "\F2E1";
}

.ms-Icon--Unlock:before {
    content: "\E785";
}

.ms-Icon--UnlockSolid:before {
    content: "\F304";
}

.ms-Icon--Unpin:before {
    content: "\E77A";
}

.ms-Icon--UnpublishContent:before {
    content: "\E31F";
}

.ms-Icon--UnSetColor:before {
    content: "\F3F9";
}

.ms-Icon--UnstackSelected:before {
    content: "\E7FE";
}

.ms-Icon--Unsubscribe:before {
    content: "\EDA0";
}

.ms-Icon--UnsyncFolder:before {
    content: "\E8F6";
}

.ms-Icon--UnsyncOccurence:before {
    content: "\F4A4";
}

.ms-Icon--Untag:before {
    content: "\F60B";
}

.ms-Icon--Up:before {
    content: "\E74A";
}

.ms-Icon--UpdateRestore:before {
    content: "\E777";
}

.ms-Icon--UpgradeAnalysis:before {
    content: "\EA0B";
}

.ms-Icon--Upload:before {
    content: "\E898";
}

.ms-Icon--URLBlock:before {
    content: "\E3FE";
}

.ms-Icon--USB:before {
    content: "\E88E";
}

.ms-Icon--UserEvent:before {
    content: "\F69C";
}

.ms-Icon--UserFollowed:before {
    content: "\F25C";
}

.ms-Icon--UserGauge:before {
    content: "\F6ED";
}

.ms-Icon--UserOptional:before {
    content: "\F767";
}

.ms-Icon--UserPause:before {
    content: "\F2BA";
}

.ms-Icon--UserRemove:before {
    content: "\F69B";
}

.ms-Icon--UserSync:before {
    content: "\F2B9";
}

.ms-Icon--UserWarning:before {
    content: "\E368";
}

.ms-Icon--UserWindow:before {
    content: "\E4ED";
}

.ms-Icon--Vacation:before {
    content: "\F49F";
}

.ms-Icon--Vaccination:before {
    content: "\EAE0";
}

.ms-Icon--VaccinationRecent:before {
    content: "\E550";
}

.ms-Icon--Variable:before {
    content: "\F305";
}

.ms-Icon--Variable2:before {
    content: "\F86D";
}

.ms-Icon--Variable3:before {
    content: "\E4D1";
}

.ms-Icon--VariableGroup:before {
    content: "\F31B";
}

.ms-Icon--VB:before {
    content: "\F2F2";
}

.ms-Icon--VennDiagram:before {
    content: "\F273";
}

.ms-Icon--VerifiedBrand:before {
    content: "\F7BD";
}

.ms-Icon--VerifiedBrandSolid:before {
    content: "\F6AD";
}

.ms-Icon--VersionControlPush:before {
    content: "\F664";
}

.ms-Icon--VerticalDistributeCenter:before {
    content: "\F4FA";
}

.ms-Icon--Video:before {
    content: "\E714";
}

.ms-Icon--Video360Generic:before {
    content: "\F609";
}

.ms-Icon--VideoLightOff:before {
    content: "\EA74";
}

.ms-Icon--VideoOff:before {
    content: "\F4B0";
}

.ms-Icon--VideoOff2:before {
    content: "\E43C";
}

.ms-Icon--VideoSearch:before {
    content: "\F4EA";
}

.ms-Icon--VideoSolid:before {
    content: "\EA0C";
}

.ms-Icon--View:before {
    content: "\E890";
}

.ms-Icon--ViewAll:before {
    content: "\E8A9";
}

.ms-Icon--ViewAll2:before {
    content: "\EF56";
}

.ms-Icon--ViewDashboard:before {
    content: "\F246";
}

.ms-Icon--ViewInAR:before {
    content: "\E41F";
}

.ms-Icon--ViewList:before {
    content: "\F247";
}

.ms-Icon--ViewListGroup:before {
    content: "\F248";
}

.ms-Icon--ViewListTree:before {
    content: "\F249";
}

.ms-Icon--ViewOriginal:before {
    content: "\E7B4";
}

.ms-Icon--VirtualNetwork:before {
    content: "\F815";
}

.ms-Icon--VisioDiagram:before {
    content: "\F2A0";
}

.ms-Icon--VisioDiagramSync:before {
    content: "\F762";
}

.ms-Icon--VisioDocument:before {
    content: "\F2A9";
}

.ms-Icon--VisioLogo:before {
    content: "\F2A7";
}

.ms-Icon--VisioLogo16:before {
    content: "\F3A3";
}

.ms-Icon--VisioLogoInverse:before {
    content: "\ED7D";
}

.ms-Icon--VisioLogoInverse16:before {
    content: "\F3A2";
}

.ms-Icon--VisualBasicLanguage:before {
    content: "\F2F1";
}

.ms-Icon--VisuallyImpaired:before {
    content: "\F866";
}

.ms-Icon--VisualsFolder:before {
    content: "\F520";
}

.ms-Icon--VisualsStore:before {
    content: "\F521";
}

.ms-Icon--VisualStudioIDELogo32:before {
    content: "\F5D0";
}

.ms-Icon--VisualStudioLogo:before {
    content: "\EC22";
}

.ms-Icon--VoicemailForward:before {
    content: "\ED87";
}

.ms-Icon--VoicemailIRM:before {
    content: "\F421";
}

.ms-Icon--VoicemailReply:before {
    content: "\ED88";
}

.ms-Icon--Volume0:before {
    content: "\E992";
}

.ms-Icon--Volume1:before {
    content: "\E993";
}

.ms-Icon--Volume2:before {
    content: "\E994";
}

.ms-Icon--Volume3:before {
    content: "\E995";
}

.ms-Icon--VolumeDisabled:before {
    content: "\EA85";
}

.ms-Icon--VSTSAltLogo1:before {
    content: "\F382";
}

.ms-Icon--VSTSAltLogo2:before {
    content: "\F383";
}

.ms-Icon--VSTSLogo:before {
    content: "\F381";
}

.ms-Icon--Waffle:before {
    content: "\ED89";
}

.ms-Icon--WaffleOffice365:before {
    content: "\F4E0";
}

.ms-Icon--WaitlistConfirm:before {
    content: "\F550";
}

.ms-Icon--WaitlistConfirmMirrored:before {
    content: "\F551";
}

.ms-Icon--Warning:before {
    content: "\E7BA";
}

.ms-Icon--Warning12:before {
    content: "\F62F";
}

.ms-Icon--WarningSolid:before {
    content: "\F736";
}

.ms-Icon--WavingHand:before {
    content: "\F807";
}

.ms-Icon--WebAppBuilderFragment:before {
    content: "\E314";
}

.ms-Icon--WebAppBuilderFragmentCreate:before {
    content: "\E31B";
}

.ms-Icon--WebAppBuilderFragmentLock:before {
    content: "\E4DD";
}

.ms-Icon--WebAppBuilderFragmentTest:before {
    content: "\E4DE";
}

.ms-Icon--WebAppBuilderModule:before {
    content: "\E313";
}

.ms-Icon--WebAppBuilderModuleLock:before {
    content: "\E4DF";
}

.ms-Icon--WebAppBuilderModuleTest:before {
    content: "\E4E0";
}

.ms-Icon--WebAppBuilderSlot:before {
    content: "\E315";
}

.ms-Icon--Webcam2:before {
    content: "\E960";
}

.ms-Icon--Webcam2Off:before {
    content: "\E36D";
}

.ms-Icon--WebComponents:before {
    content: "\EC8B";
}

.ms-Icon--WebEnvironment:before {
    content: "\E3DB";
}

.ms-Icon--WebPublish:before {
    content: "\F52F";
}

.ms-Icon--Website:before {
    content: "\EB41";
}

.ms-Icon--WebTemplate:before {
    content: "\F6B2";
}

.ms-Icon--Weights:before {
    content: "\EADB";
}

.ms-Icon--Wheelchair:before {
    content: "\F31F";
}

.ms-Icon--WhiteBoardApp16:before {
    content: "\F673";
}

.ms-Icon--WhiteBoardApp32:before {
    content: "\F674";
}

.ms-Icon--WifiEthernet:before {
    content: "\EE77";
}

.ms-Icon--WifiWarning4:before {
    content: "\EB63";
}

.ms-Icon--WindDirection:before {
    content: "\EBE6";
}

.ms-Icon--WindowEdit:before {
    content: "\F50E";
}

.ms-Icon--WindowsLogo:before {
    content: "\E782";
}

.ms-Icon--Wines:before {
    content: "\EABF";
}

.ms-Icon--WipePhone:before {
    content: "\ED8D";
}

.ms-Icon--WordDocument:before {
    content: "\EF71";
}

.ms-Icon--WordLogo:before {
    content: "\F1E3";
}

.ms-Icon--WordLogo16:before {
    content: "\F391";
}

.ms-Icon--WordLogoInverse:before {
    content: "\EC29";
}

.ms-Icon--WordLogoInverse16:before {
    content: "\F390";
}

.ms-Icon--Work:before {
    content: "\E821";
}

.ms-Icon--WorkFlow:before {
    content: "\EA01";
}

.ms-Icon--WorkforceManagement:before {
    content: "\EE0F";
}

.ms-Icon--WorkItem:before {
    content: "\F314";
}

.ms-Icon--WorkItemAlert:before {
    content: "\F78F";
}

.ms-Icon--WorkItemBar:before {
    content: "\F35C";
}

.ms-Icon--WorkItemBarSolid:before {
    content: "\F35D";
}

.ms-Icon--WorkItemBug:before {
    content: "\F315";
}

.ms-Icon--World:before {
    content: "\E909";
}

.ms-Icon--WorldClock:before {
    content: "\E918";
}

.ms-Icon--XRay:before {
    content: "\E551";
}

.ms-Icon--YammerLogo:before {
    content: "\ED19";
}

.ms-Icon--ZeroDayCalendar:before {
    content: "\E547";
}

.ms-Icon--ZipFolder:before {
    content: "\F012";
}

.ms-Icon--Zoom:before {
    content: "\E71E";
}

.ms-Icon--ZoomIn:before {
    content: "\E8A3";
}

.ms-Icon--ZoomOut:before {
    content: "\E71F";
}

.ms-Icon--ZoomToFit:before {
    content: "\F649";
}
