body {
}

html, body {
}

#root {
    max-width: 1600px;
    margin: 0px auto 0 auto;
}

/*
#page {
    min-height: 99vh;
    display: flex !important;
    flex-direction: column !important;
}
*/


#content {
    padding: 20px 30px 20px 30px;
    animation: fadein 1s;
    /*
    width: 90%;
    max-width: 1800px;
    margin: 0px auto 0 auto;
    border:1px solid red;
    flex-grow: 1;
    margin-top: 55px;
    margin-bottom: 10px;
    padding: 20px;

     */
}


.ant-layout.ant-layout-has-sider {
    padding: 30px;


}


.ant-layout.ant-layout-has-sider > :last-child {

    margin: 0px 0px 0px 30px;;
}


/******************************************/
#appName {

    color: #163860 !important;;
    font-weight: bold;
    text-shadow: 1px 1px 3px #cecece;
    font-family: var(--app-title-font-family) !important;
    font-size: 13px;
}


*:not(input):not(textarea) {
    /* user-select: none;*/
}

::placeholder,
*:not(i):not(svg) {
    font-family: var(--default-font-family), Helvetica Neue, Helvetica, Arial, sans-serif !important;

}


