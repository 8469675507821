/******************************/
::-webkit-scrollbar {
    width: 15px !important;
    height: 15px !important;
}

::-webkit-scrollbar-track {
    background-color: transparent !important;
}

::-webkit-scrollbar-track-piece {
    background: transparent !important;
    margin: 2px !important;
}

::-webkit-scrollbar-thumb {
    height: 40px !important;
    border: 1px solid rgba(0, 0, 0, 0) !important;
    background-clip: padding-box !important;
    border-radius: 7px !important;
    background-color: rgba(0, 0, 0, 0.08) !important;

}

::-webkit-scrollbar-thumb:window-inactive {
    background-color: rgba(0, 0, 0, 0.08) !important;
}

::-webkit-scrollbar-thumb:vertical {
    border-right: 1px solid transparent !important;
    border-top: 1px solid transparent !important;
}

::placeholder,
::-webkit-input-placeholder {
    font-size: inherit !important;
    font-family: inherit !important;
}

/******************************/
@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }

}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadein {
    animation: fadein 1s;
}

/******************************/

td.overflow,
.overflow {
    overflow: auto !important;
}

.shadow-1 {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 30px -9px !important;
    /*box-shadow:0 0px 10px rgba(48, 55, 66, 0.15)!important*/
}


.shadow-2 {
    /*margin: 6px;*/
    /* box-shadow:0 2px 8px -2px rgb(0 0 0 / 5%), 0 1px 4px -1px rgb(0 0 0 / 7%), 0 0 1px 0 rgb(0 0 0 / 8%)!important;;*/
    /* box-shadow:rgba(0, 0, 0, 0.12) 0px 2px 30px -9px!important;*/
    /*box-shadow:0 0.16rem 0.36rem 0 rgb(0 0 0 / 13%), 0 0.03rem 0.09rem 0 rgb(0 0 0 / 11%);*/
    /* box-shadow: 0 2px 8px #f0f1f2;*/
    /*box-shadow:0 2px 8px #f0f1f2;  ;*/
    /*box-shadow: 0 0 10px rgba(48, 55, 66, 0.15)  ;*/
    /*box-shadow: 0 1px 2px rgba(48, 54, 60, 0.15);*/
    /*box-shadow: 0 1px 4px 0 rgba(48, 55, 66, 0.15), 0 0px 1px 0 rgba(48, 55, 66, 0.15);*/
    /*box-shadow:0 1px 5px #E0E0E0;*/
    /*box-shadow: 0 0 10px rgba(48, 55, 66, .15);*/
    /*box-shadow:rgba(46, 41, 51, 0.08) 0px 1px 2px, rgba(71, 63, 79, 0.08) 0px 2px 4px;*/
    box-shadow: 0 2px 8px #f0f1f2 !important;
    /*box-shadow: 0 13px 30px rgba(0, 0, 0, 0.07);*/
}

.padding {
    padding: 20px !important;
}

.fullWidth {
    width: 100%;
}

/******************************/
.deepCenter *:not(input),
.center {
    text-align: center !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.right {
    text-align: right !important;
}

.italic {
    font-style: italic;
}

.bold,
.bold * {
    font-weight: bold;
}

.userSelect {
    user-select: all !important;
}

/** utilsé dans les select avec les badge **/
/** nécéssite un width dans le composant **/
.flex {
    display: flex !important;
    align-items: center !important;;
    justify-content: space-between !important;;
    column-gap: 10px
}

.flex span {
    text-overflow: ellipsis;
}

.wrap {
    white-space: normal !important;

}

::placeholder {
    font-size: var(--default-font-size) !important;
}

.underline {
    text-decoration: underline;
}

.ant-table tr.table-row-selected td {
    background-color: #fafafa !important;
}

.opacity {
    opacity: 0.5;
}

i:hover {
    opacity: 0.85;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

